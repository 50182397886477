import './../../styles/pages/members.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
  createNewMember,
  getMembersListData,
  updateMemberDetails
} from '../../core/services/members';
import { dateFormat } from '../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import CsButton from './../../comps/CsButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Members = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [lastDocument, setLastDocument] = useState();
  const [globalFilter, setGlobalFilter] = useState();
  const [searchedWithGlobal, setSearchedWithGlobal] = useState(false);

  const loadData = () => {
    setIsLoading(true);

    getMembersListData({ action: 'load', batchLimit: limit }).then(res => {
      setData(res.members);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadMoreData = () => {
    setIsLoading(true);

    getMembersListData({
      action: 'more',
      batchLimit: limit,
      start: lastDocument
    }).then(res => {
      setData([...data, ...res.members]);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadSearchData = () => {
    setIsLoading(true);

    getMembersListData({
      action: 'search',
      batchLimit: null,
      start: null,
      search: globalFilter
    }).then(res => {
      setData(res.members);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (globalFilter !== undefined) {
      loadSearchData();
      setSearchedWithGlobal(true);
    }
  }, [globalFilter]);

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateMemberDetails({
      ...values,
      id: row.original.id,
      oldEmail: row.original.email
    });
    loadData();
  };

  const handleCreate = async ({ row, values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createNewMember(values);
    loadData();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'client_id',
        header: 'Client ID',
        size: 50
      },
      {
        accessorKey: 'staff_id',
        header: 'Employee ID',
        size: 50
      },
      {
        accessorKey: 'client_type',
        header: 'Client Type',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Member',
            value: 'Member'
          },
          {
            label: 'Associate',
            value: 'Associate'
          },
          {
            label: 'Retiree',
            value: 'Retiree'
          },
          {
            label: 'Staff',
            value: 'Staff'
          }
        ]
      },
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 50
      },
      {
        accessorKey: 'middle_name',
        header: 'Middle Name',
        size: 50
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 50
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 50
      },
      {
        accessorKey: 'contact',
        header: 'Contact',
        size: 50
      },
      {
        accessorKey: 'sex',
        header: 'Gender',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Male',
            value: 'Male'
          },
          {
            label: 'Female',
            value: 'Female'
          }
        ]
      },
      {
        accessorKey: 'dob',
        Cell: ({ cell }) => dateFormat(cell.getValue()),
        header: 'Date of Birth',
        size: 50,
        muiEditTextFieldProps: {
          type: 'date'
        }
      },
      {
        accessorKey: 'date_opened',
        accessorFn: row => dateFormat(row.date_opened),
        header: 'Date Opened',
        size: 50
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Active',
            value: 'Active'
          },
          {
            label: 'Deactivated',
            value: 'Deactivated'
          }
        ]
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'modal',
    enableRowActions: true,
    enableEditing: true,
    manualFiltering: true,
    manualPagination: true,
    enableBottomToolbar: true,
    state: {
      isLoading,
      globalFilter
    },
    muiTableContainerProps: {
      sx: { height: '70vh' }
    },
    onGlobalFilterChange: setGlobalFilter,
    renderBottomToolbar: () => {
      return (
        <Box sx={{ padding: 1 }}>
          <p style={{ fontSize: 14 }}>
            Total Rows: <b>{data.length}</b>
          </p>
        </Box>
      );
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Member</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onEditingRowSave: handleUpdate,
    onCreatingRowSave: handleCreate
  });

  return (
    <div className="members">
      <div className="members__list">
        <div
          style={{
            display: 'flex',
            gap: 10,
            padding: 10,
            overflow: 'auto'
          }}
        >
          <CsButton
            icon={faPlus}
            label="New"
            onClick={() => {
              table.setCreatingRow(
                createRow(table, {
                  sex: 'Male',
                  created_at: new Date(),
                  updated_at: new Date(),
                  status: 'Active'
                })
              );
            }}
          />

          {!data.length || searchedWithGlobal ? (
            <CsButton
              label="Load Data"
              onClick={() => {
                loadData();
                setSearchedWithGlobal(false);
              }}
            />
          ) : null}

          {data.length && !searchedWithGlobal ? (
            <CsButton
              label="Load More"
              onClick={() => {
                loadMoreData();
              }}
            />
          ) : null}

          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="set-limit">Batch Limit</InputLabel>
            <Select
              id="set-limit"
              label="Batch Limit"
              value={limit}
              onChange={event => {
                setData([]);
                setLimit(event.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="table">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default Members;
