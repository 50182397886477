import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

export const getMemberBalanceData = async data => {
  try {
    // const b = sessionStorage.getItem('dsebspcc_member_balances');

    // if (b && !data.date) {
    //   return { balances: JSON.parse(b) };
    // }

    const balances = await getDocs(
      query(
        collection(db, 'balances'),
        where('client_id', '==', data.user.client_id),
        where(
          'created_at',
          '==',
          data.date ?? data.user.recent_soa_log.assigned_at
        )
      )
    );

    let balancesData = [];

    balances.forEach(doc => {
      balancesData = [...balancesData, { ...doc.data(), id: doc.id }];
    });

    // sessionStorage.setItem(
    //   'dsebspcc_member_balances',
    //   JSON.stringify(balancesData)
    // );

    return { balances: balancesData };
  } catch (error) {
    return error;
  }
};
