import {
  addDoc,
  collection,
  doc,
  endAt,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  startAt,
  updateDoc,
  where
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { updateUserEmailAuth } from './cloud-functions/user-auth';

export const getMembersListData = async ({
  action,
  batchLimit,
  start,
  search
}) => {
  try {
    let members = null;

    if (action === 'more') {
      members = await getDocs(
        query(collection(db, 'clients'), startAfter(start), limit(batchLimit))
      );
    } else if (action === 'search') {
      members = await getDocs(
        query(
          collection(db, 'clients'),
          or(
            where('email', '==', search),
            where('client_id', '==', search),
            where('first_name', '==', search),
            where('middle_name', '==', search),
            where('last_name', '==', search)
          )
        )
      );
    } else {
      members = await getDocs(
        query(collection(db, 'clients'), limit(batchLimit))
      );
    }

    const last = members.docs[members.docs.length - 1];

    let membersData = [];

    members.forEach(doc => {
      membersData = [...membersData, { ...doc.data(), id: doc.id }];
    });

    return { members: membersData, last };
  } catch (error) {
    return error;
  }
};

export const createNewMember = async data => {
  const password = Math.random().toString(36).slice(2, 10);
  const details = {
    email: data.email,
    password: password,
    client_id: data.client_id,
    staff_id: data?.staff_id ?? null,
    client_type: data.client_type,
    first_name: data.first_name,
    middle_name: data?.middle_name ?? null,
    last_name: data.last_name,
    contact: data?.contact ?? null,
    sex: data?.sex ?? null,
    dob: data?.dob ?? null,
    date_opened: data?.date_opened ?? null,
    status: data.status,
    created_by: auth.currentUser.email,
    created_at: new Date().getTime()
  };

  try {
    const res = await addDoc(collection(db, 'clients'), details);

    // if (res.id) {
    //   await addDoc(collection(db, 'auto_send_email'), {
    //     type: 'user_signup',
    //     to: [data.email],
    //     message: {
    //       subject: 'Setup your new DSEBSPCC Website Account',
    //       html: `<p style="color: black">Hi ${
    //         data.first_name || data.last_name
    //       },
    //       <br/><br/>
    //       Greetings!
    //       <br/><br/>
    //       Please setup your account to access your benefits as a member of DSEBSPCC.
    //       <br/>
    //       Go to ${process.env.REACT_APP_ENV_PATH} to login.
    //       <br/>
    //       Your email is <b>${
    //         data.email
    //       }</b> and your temporary password is <b>${password}</b>.
    //       <br/>
    //       After logging in, please change your password by going to <b>Settings</b>.
    //       <br/><br/>
    //       <i>This is system generated. Do not reply to this email.</i>
    //       <br/>
    //       <i>Should you encounter any problems or for any concerns, feel free to reply to this email or contact us at systemadmin@dsebspcc.com | DL. 8708-7597 Local. 2992/2993
    //       Carmelo Z. Lagrason
    //       Justin Cyril L. Espiritu</i>
    //       </p>`
    //     }
    //   });
    // }

    alert(`Temporary password: ${password}`);

    return res;
  } catch (error) {
    alert(error);
    return error;
  }
};

export const updateMemberDetails = async data => {
  const details = {
    staff_id: data.staff_id,
    client_type: data.client_type,
    first_name: data.first_name,
    middle_name: data.middle_name,
    last_name: data.last_name,
    contact: data.contact,
    sex: data.sex,
    dob: data.dob,
    status: data.status,
    updated_by: auth.currentUser.email,
    updated_at: new Date().getTime()
  };

  if (
    auth.currentUser.email === 'kevinrichardt.lazarito@gmail.com' ||
    auth.currentUser.email === 'systemadmin@dsebspcc.com'
  ) {
    details.email = data.email;

    debugger;
    await updateUserEmailAuth({
      email: data.oldEmail,
      newEmail: data.email
    }).catch(error => {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      // ...
      debugger;
    });
  }

  try {
    const res = await updateDoc(doc(db, 'clients', data.id), details);

    return res;
  } catch (error) {
    debugger;
    alert(error);
    return error;
  }
};

export const generateOnlineLogs = async ({ start, end }) => {
  try {
    const res = await getDocs(
      query(
        collection(db, 'user_online_logs'),
        orderBy('executed_at'),
        startAt(new Date(start + ' 00:00:00').getTime()),
        endAt(new Date(end + ' 23:59:59').getTime())
      )
    );

    let data = [];

    res.forEach(doc => {
      data = [...data, { ...doc.data(), id: doc.id }];
    });

    return data;
  } catch (error) {
    return error;
  }
};
