import './../../styles/pages/pre-membership.scss';
import {
  createCode,
  createGuide,
  createQuestion,
  deleteCode,
  deleteGuide,
  deleteQuestion,
  getCodeListData,
  getGuideListData,
  getQuestionListData,
  getVideoSeminar,
  updateCode,
  updateGuide,
  updateQuestion,
  updateQuestionOptions,
  uploadVideoSeminar
} from '../../core/services/pre-membership';
import CsButton from './../../comps/CsButton';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import { ExpandMore, Delete, Edit } from '@mui/icons-material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const CodesTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getCodeListData().then(res => {
      setData(res.codes);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ table, values }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    const res = await createCode(values);
    if (res.error) {
      alert(res.message);
    }
    getData();
  };

  const handleUpdate = async ({ row, table, values }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateCode({
      ...values,
      id: row.original.id
    });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Are you sure you want to delete this code?`)) {
      setIsLoading(true);
      await deleteCode(row.original.id);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'code',
        header: 'Code'
      },
      // {
      //   accessorKey: 'expiration',
      //   header: 'Expiration Date'
      // },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Valid',
            value: 'Valid'
          },
          {
            label: 'Invalid',
            value: 'Invalid'
          }
        ]
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          table.setCreatingRow(createRow(table));
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create Code</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Code</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return <MaterialReactTable table={table} />;
};

const QuestionOptions = ({
  id,
  data = [],
  refresh = () => {},
  loading = () => {}
}) => {
  const handleCreate = async ({ values, table }) => {
    const options = [...data.map(x => x.option), values.option];
    table.setCreatingRow(null);
    loading(true);
    await updateQuestionOptions({ id, options });
    refresh();
  };

  const handleUpdate = async ({ row, values, table }) => {
    const updatedData = data.map(x => {
      if (x.id === row.original.id) {
        return {
          id: row.original.id,
          option: values.option
        };
      } else return x;
    });
    const options = updatedData.map(x => x.option);
    table.setCreatingRow(null);
    loading(true);
    await updateQuestionOptions({ id, options });
    refresh();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Click confirm to delete?`)) {
      const updatedData = data.filter(x => x.id !== row.original.id);
      const options = updatedData.map(x => x.option);
      table.setCreatingRow(null);
      loading(true);
      await updateQuestionOptions({ id, options });
      refresh();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'option',
        header: 'Option',
        muiEditTextFieldProps: {
          required: true
        }
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableTopToolbar: false,
    enableBottomToolbar: false,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Option</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Option</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <>
      <CsButton
        label="Add Option"
        onClick={() => {
          table.setCreatingRow(true);
        }}
      />
      <br />
      <MaterialReactTable table={table} />
    </>
  );
};

const QuizTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getQuestionListData().then(res => {
      setData(res.questions.sort((a, b) => a.order.localeCompare(b.order)));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ table, values }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    const res = await createQuestion(values);
    if (res.error) {
      alert(res.message);
    }
    getData();
  };

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateQuestion({
      ...values,
      id: row.original.id
    });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Are you sure you want to delete this question?`)) {
      setIsLoading(true);
      await deleteQuestion(row.original.id);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'order',
        header: 'Order'
      },
      {
        accessorKey: 'question_type',
        header: 'Question Type',
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Fill in the blank',
            value: 'Fill in the blank'
          },
          {
            label: 'Multiple choice',
            value: 'Multiple choice'
          }
        ]
      },
      {
        accessorKey: 'question',
        header: 'Question'
      },
      {
        accessorKey: 'answer',
        header: 'Answer'
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          table.setCreatingRow(createRow(table));
        }}
      />
    ),
    renderDetailPanel: ({ row }) => {
      const data =
        row.original.options?.map((x, index) => ({
          id: index + 1,
          option: x
        })) || [];

      return row.original.question_type === 'Fill in the blank' ? null : (
        <QuestionOptions
          id={row.original.id}
          data={data}
          refresh={() => {
            getData();
          }}
          loading={value => {
            setIsLoading(value);
          }}
        />
      );
    },
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Question</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Question</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return <MaterialReactTable table={table} />;
};

const GuideTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState();
  const [instruction, setInstruction] = useState();

  const getData = () => {
    getGuideListData().then(res => {
      setData(res.guides.sort((a, b) => a.order.localeCompare(b.order)));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    const res = await createGuide({ order, instruction });
    if (res.error) {
      alert(res.message);
    }
    getData();
  };

  const handleUpdate = async ({ row, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateGuide({
      order,
      instruction,
      id: row.original.id
    });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Are you sure you want to delete this instruction?`)) {
      setIsLoading(true);
      await deleteGuide(row.original.id);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'order',
        header: 'Order'
      },
      {
        accessorKey: 'instruction',
        header: 'Instruction'
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setOrder(row.original.order);
              setInstruction(row.original.instruction);
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          setOrder('');
          setInstruction('');
          table.setCreatingRow(createRow(table));
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create Instruction</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          <TextField
            label="Order"
            variant="standard"
            value={order}
            onChange={event => {
              setOrder(event.target.value);
            }}
          />

          <TextField
            label="Set instructions here..."
            variant="outlined"
            value={instruction}
            onChange={event => {
              setInstruction(event.target.value);
            }}
            minRows={7}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Instruction</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          <TextField
            label="Order"
            variant="standard"
            value={order}
            onChange={event => {
              setOrder(event.target.value);
            }}
          />

          <TextField
            label="Set instructions here..."
            variant="outlined"
            value={instruction}
            onChange={event => {
              setInstruction(event.target.value);
            }}
            minRows={7}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return <MaterialReactTable table={table} />;
};

const PreMembership = () => {
  const uploadVideoRef = useRef(null);
  const [videoSeminar, setVideoSeminar] = useState('');

  useEffect(() => {
    getVideoSeminar().then(video => {
      setVideoSeminar(video);
    });
  }, []);

  const handleUploadVideoSeminar = async event => {
    if (event.target.files.length) {
      const res = await uploadVideoSeminar({ file: event.target.files[0] });

      if (res) {
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        setVideoSeminar(objectUrl);
        alert('Uploaded successfully!');
      }
    }
  };

  return (
    <div className="pre-membership">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <p>Set pre-membership invitation codes</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="codes-table">
            <CodesTable />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <p>Step 1: Watch video seminar</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="step-one">
            <div className="video-container">
              <input
                ref={uploadVideoRef}
                type="file"
                accept="video/*"
                onChange={handleUploadVideoSeminar}
                hidden
              />

              <video
                src={videoSeminar}
                controls
                width="100%"
                height="100%"
                style={{ objectFit: 'fill' }}
              >
                <source src={videoSeminar} type="video/webm" />
                <source src={videoSeminar} type="video/mp4" />
              </video>
            </div>
            <CsButton
              label="Upload"
              onClick={() => {
                uploadVideoRef.current.click();
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <p>Step 2: Take a quick and easy quiz</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="step-two">
            <div className="quiz-container">
              <QuizTable />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <p>Step 3: Follow our guide to become a member!</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="step-three">
            <div className="guide-container">
              <GuideTable />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PreMembership;
