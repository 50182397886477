import './../../styles/pages/settings.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
  createStaff,
  getStaffsListData,
  updateStaffDetails
} from '../../core/services/settings';
import { dateFormat } from '../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { faEye, faEyeSlash, faPlus } from '@fortawesome/free-solid-svg-icons';
import CsButton from '../../comps/CsButton';
import {
  getUserFromSessionStorage,
  logout,
  updateUserEmail,
  updateUserPassword
} from '../../core/services/auth';
import CsInput from '../../comps/CsInput';

const AdminSettings = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState('');

  const getData = () => {
    getStaffsListData().then(res => {
      setData(res.staffs);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);

    const res = await createStaff({ ...values, password });

    if (res.error) {
      alert(res.message);
    }

    getData();
  };
  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateStaffDetails({ ...values, id: row.original.id });
    getData();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'staff_id',
        header: 'Employee ID',
        size: 50
      },
      // {
      //   accessorKey: 'role',
      //   header: 'Role',
      //   size: 50,
      // },
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 50
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 50
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 50
      },
      {
        accessorKey: 'contact',
        header: 'Contact',
        size: 50
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Male',
            value: 'Male'
          },
          {
            label: 'Female',
            value: 'Female'
          }
        ]
      },
      {
        accessorKey: 'created_at',
        accessorFn: row => dateFormat(row.created_at),
        header: 'Date Created',
        size: 50,
        enableEditing: false
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Active',
            value: 'Active'
          },
          {
            label: 'Deactivated',
            value: 'Deactivated'
          }
        ]
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          table.setCreatingRow(
            createRow(table, {
              gender: 'Male',
              created_at: new Date(),
              updated_at: new Date(),
              status: 'Active'
            })
          );
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create Staff</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
          <TextField
            label="Password"
            type="password"
            variant="standard"
            value={password}
            onChange={event => {
              setPassword(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Staff</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <div className="settings">
      <div className="settings__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

const MemberSettings = () => {
  const [currPass, setCurrPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [hidePass, setHidePass] = useState(true);

  const [newEmail, setNewEmail] = useState();

  const changePassword = () => {
    if (newPass !== confirmPass)
      return alert('New password and confirm password does not match.');

    updateUserPassword(currPass, newPass).then(res => {
      if (res.success) {
        alert('Password has been updated! Please relogin.');
        logout();
      }
    });
  };

  const updateEmail = () => {
    updateUserEmail(currPass, newEmail).then(res => {
      if (res.success) {
        alert(
          'Email has been updated! Please check your email and verify to relogin.'
        );
        logout();
      }
    });
  };

  return (
    <div className="settings">
      <div style={{ width: 300 }}>
        <label htmlFor="">Current Password</label>
        <CsInput
          type={hidePass ? 'password' : 'text'}
          value={currPass}
          icon={hidePass ? faEye : faEyeSlash}
          onChange={value => setCurrPass(value)}
          onIconClick={() => setHidePass(!hidePass)}
        />
      </div>

      <br />

      <div className="members">
        <div className="members__grid">
          <h3>Change Password</h3>

          <hr />
          <br />

          <label htmlFor="">New Password</label>
          <CsInput
            type={hidePass ? 'password' : 'text'}
            value={newPass}
            icon={hidePass ? faEye : faEyeSlash}
            onChange={value => setNewPass(value)}
            onIconClick={() => setHidePass(!hidePass)}
          />

          <br />

          <label htmlFor="">Confirm Password</label>
          <CsInput
            type={hidePass ? 'password' : 'text'}
            value={confirmPass}
            icon={hidePass ? faEye : faEyeSlash}
            onChange={value => setConfirmPass(value)}
            onIconClick={() => setHidePass(!hidePass)}
          />

          <br />

          <CsButton
            label="Confirm Changes"
            onClick={() => {
              changePassword();
            }}
          />
        </div>

        <div className="members__grid">
          <h3>Update Email</h3>

          <hr />
          <br />

          <label htmlFor="">New Email</label>
          <CsInput value={newEmail} onChange={value => setNewEmail(value)} />

          <br />

          <CsButton
            label="Confirm Changes"
            onClick={() => {
              updateEmail();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Settings = () => {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');

  return isAdmin ? <AdminSettings /> : <MemberSettings />;
};

export default Settings;
