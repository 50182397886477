import { useNavigate } from 'react-router-dom';
import CsButton from '../../comps/CsButton';
import CsInput from '../../comps/CsInput';
import { forgotPassword } from '../../core/services/auth';
import '../../styles/login.scss';
import React, { useState } from 'react';

const ForgotPassword = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState();

  const handleSubmit = async () => {
    forgotPassword(email).then(res => {
      alert(
        'Request sent! Please check your email and follow the link provided.'
      );
    });
  };

  return (
    <div className="login">
      <div className="login__grid">
        <div className="login__form">

          <div className="logo">
            <img src="/logo-icon.png" alt="logo icon" />
          </div>

          <div className="form">
            <div className="input-group">
              <label htmlFor="">Please input your email account</label>
              <CsInput
                classContainer="login-input"
                value={email}
                onChange={setEmail}
              />
            </div>

            <CsButton
              label="Send reset password request"
              classContainer="login-btn"
              onClick={handleSubmit}
            />

            <CsButton
              label="Back to login"
              classContainer="back-btn"
              onClick={() => {
                nav('/login');
              }}
            />
          </div>
        </div>

        <div className="login__image">
          <img src="/bsp-building.png" alt="bsp building" style={{ visibility: 'hidden' }} />

          <div className="banner">
            <img src="/logo-icon.png" alt="logo icon" />
            {/* <p>
              DSE (BSP) CREDIT COOPERATIVE BANKO SENTRAL NG PILIPINAS MANIILA,
              PHILIPPINES
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
