import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../firebase';
import { uniqBy } from 'lodash';

export const getMemberDashboardData = async data => {
  try {
    // const b = JSON.parse(sessionStorage.getItem('dsebspcc_member_balances'));
    // const l = JSON.parse(sessionStorage.getItem('dsebspcc_member_loans'));

    // if (b && l && b.length && l.length && !data.date) {
    //   return { balances: b, loans: l };
    // }

    const [balances, loans] = await Promise.all([
      getDocs(
        query(
          collection(db, 'balances'),
          where('client_id', '==', data.user.client_id),
          where(
            'created_at',
            '==',
            data.date ?? data.user.recent_soa_log.assigned_at
          )
        )
      ),
      getDocs(
        query(
          collection(db, 'loans'),
          where('client_id', '==', data.user.client_id),
          where(
            'created_at',
            '==',
            data.date ?? data.user.recent_soa_log.assigned_at
          )
        )
      )
    ]);

    let balancesData = [];
    let loansData = [];

    balances.forEach(doc => {
      balancesData = [...balancesData, { ...doc.data(), id: doc.id }];
    });

    loans.forEach(doc => {
      loansData = [...loansData, { ...doc.data(), id: doc.id }];
    });

    // sessionStorage.setItem(
    //   'dsebspcc_member_balances',
    //   JSON.stringify(balancesData)
    // );
    // sessionStorage.setItem('dsebspcc_member_loans', JSON.stringify(loansData));

    return { balances: balancesData, loans: loansData };
  } catch (error) {
    return error;
  }
};

export const getAdminLoanApplicationData = async () => {
  try {
    const loans = await getDocs(
      query(
        collection(db, 'loan_applications'),
        where('status', '==', 'Pending')
      )
    );

    let loansData = [];

    loans.forEach(doc => {
      loansData = [...loansData, { ...doc.data(), id: doc.id }];
    });

    return { loans: loansData };
  } catch (error) {
    return error;
  }
};

export const getLatestSOADate = async () => {
  try {
    const res = await getDocs(
      query(
        collection(db, 'soa_upload_logs'),
        orderBy('executed_at', 'desc'),
        limit(1)
      )
    );

    return res.docs[0].data();
  } catch (error) {
    return error;
  }
};

export const getSOALogs = async () => {
  try {
    let data = [];
    const res = await getDocs(collection(db, 'soa_upload_logs'));

    res.forEach(doc => {
      data = [...data, doc.data()];
    });

    return uniqBy(data, 'assigned_at').map(x => x.assigned_at);
  } catch (error) {
    return error;
  }
};
