import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  doc,
  getCountFromServer,
  query,
  where,
  getDoc,
  limit,
  updateDoc
} from 'firebase/firestore';
import { auth, db } from './../../firebase';
import { getUserFromSessionStorage } from './auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { allUsers, duplicateUsers, firstFiveHundredUsers } from '../data/users';
import _ from 'lodash';
import { DateTime } from 'luxon';

export const downloadSOA = async ({ user, date }) => {
  try {
    const [balances, loans] = await Promise.all([
      getDocs(
        query(
          collection(db, 'balances'),
          where('client_id', '==', user.client_id),
          where('created_at', '==', date ?? user.recent_soa_log.assigned_at)
        )
      ),
      getDocs(
        query(
          collection(db, 'loans'),
          where('client_id', '==', user.client_id),
          where('created_at', '==', date ?? user.recent_soa_log.assigned_at)
        )
      )
    ]);

    let balancesData = [];
    let loansData = [];

    balances.forEach(doc => {
      balancesData = [...balancesData, { ...doc.data(), id: doc.id }];
    });

    loans.forEach(doc => {
      loansData = [...loansData, { ...doc.data(), id: doc.id }];
    });

    return { balances: balancesData, loans: loansData };
  } catch (error) {
    return error;
  }
};

export const importToFirestore = async data => {
  try {
    let count = 0;
    let failed = [];

    for (let x in data.soa) {
      const d = data.soa[x];
      const validDate = DateTime.fromFormat(d.created_at, 'yyyy-MM-dd', {
        throwOnInvalid: false
      }).isValid;
      const validAmount = typeof parseFloat(d.amount) === 'number';

      if (!validDate) {
        debugger;
        alert(
          'Data contains invalid date format. Date format must be YYYY-MM-DD.'
        );
        return;
      }

      if (!validAmount) {
        debugger;
        alert(
          'Data contains invalid amount format. Amount must not be grouped, and/or contain any alphabetic character(s).'
        );
        return;
      }
    }

    const user = getUserFromSessionStorage();
    await addDoc(collection(db, 'soa_upload_logs'), {
      staff_id: user.staff_id,
      staff_name: [user.first_name, user.last_name].join(' '),
      soa_type: data.table,
      assigned_at: data.soa[0].created_at,
      executed_at: new Date().getTime()
    });

    const settled = await Promise.allSettled(
      data.soa.map(row => addDoc(collection(db, data.table), row))
    );

    settled.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        count++;
      } else {
        failed = [...failed, { status, value, reason }];
      }
    });

    debugger;
    return { count, failed };
  } catch (error) {
    debugger;
    return error;
  }
};

export const importUsers = async data => {
  try {
    debugger;
    await Promise.all([
      // ...data.map(x =>
      //   createUserWithEmailAndPassword(auth, x.email, x.password)
      // )
      // ...data.map(x =>
      //   addDoc(collection(db, 'clients'), {
      //     client_id: x.client_id,
      //     staff_id: x.staff_id,
      //     client_type: x.client_type,
      //     date_opened: x.date_opened,
      //     first_name: x.first_name,
      //     middle_name: x.middle_name,
      //     last_name: x.last_name,
      //     dob: x.dob,
      //     sex: x.sex,
      //     contact: x.contact,
      //     email: x.email,
      //     password: x.password,
      //     status: 'Active',
      //     created_at: new Date().getTime(),
      //     updated_at: new Date().getTime()
      //   })
      // )
      // ...data.map(x =>
      //   addDoc(collection(db, 'staffs'), {
      //     staff_id: x.staff_id,
      //     role: x.role,
      //     first_name: x.first_name,
      //     last_name: x.last_name,
      //     gender: x.gender,
      //     contact: x.contact,
      //     email: x.email,
      //     password: x.password,
      //     status: x.status,
      //     created_at: new Date().getTime(),
      //     updated_at: new Date().getTime()
      //   })
      // )
    ]);
    debugger;
  } catch (error) {
    debugger;
    return error;
  }
};

// window.countData = async () => {
//   const coll = query(
//     collection(db, 'balances'),
//     where('created_at', '==', '2023-12-31')
//   );
//   const snapshot = await getCountFromServer(coll);
//   console.log('count: ', snapshot.data().count);
// };

// window.deleteData = async () => {
//   try {
//     const table = 'clients';
//     let data = [];

//     const snapshot = await getDocs(
//       query(collection(db, table), where('email', '==', 'cedrickj17@gmail.com'))
//     );

//     snapshot.forEach(doc => {
//       data = [...data, doc.id];
//     });

//     debugger;
//     await Promise.all(data.map(ref => deleteDoc(doc(db, table, ref))));
//     debugger;
//   } catch (error) {
//     debugger;
//     return error;
//   }
// };

window.updateUsers = async () => {
  try {
    let data = [];

    const snapshot = await Promise.all(
      duplicateUsers.map(user =>
        getDocs(
          query(
            collection(db, 'clients'),
            where('client_id', '==', user.client_id),
            limit(1)
          )
        )
      )
    );

    snapshot.forEach(docs => {
      docs.forEach(doc => {
        const user = doc.data();
        const email = duplicateUsers.find(
          x => x.client_id === user.client_id
        ).email;
        data = [...data, { ...user, email, id: doc.id }];
      });
    });

    debugger;
    // return;
    await Promise.all(
      data.map(d =>
        updateDoc(doc(db, 'clients', d.id), {
          email: _.toString(d.email).toLocaleLowerCase(),
          status: 'Active'
        })
      )
    );
    debugger;
  } catch (error) {
    debugger;
    return error;
  }
};

export const deleteData = async ({ table, date }) => {
  try {
    debugger;
    let data = [];

    const snapshot = await getDocs(
      query(collection(db, table), where('created_at', '==', date))
    );

    snapshot.forEach(doc => {
      data = [...data, doc.id];
    });

    debugger;
    await Promise.all(data.map(ref => deleteDoc(doc(db, table, ref))));
    debugger;
  } catch (error) {
    debugger;
    return error;
  }
};
