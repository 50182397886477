import './../../styles/pages/dashboard.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { moneyFormat } from '../../core/utils/moneyFormat';
import {
  getAdminLoanApplicationData,
  getMemberDashboardData
} from '../../core/services/dashboard';
import { dateFormat } from '../../core/utils/dateFormat';
import { getUserFromSessionStorage } from '../../core/services/auth';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import {
  approveLoanApplication,
  declineLoanApplication,
  getLoanDownloadUrl
} from './../../core/services/loan-application';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Check, Close, Download } from '@mui/icons-material';

const AdminDashboard = () => {
  const user = getUserFromSessionStorage();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getAdminLoanApplicationData().then(res => {
      setData(res.loans.sort((a, b) => b.created_at - a.created_at));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleApprove = async id => {
    setIsLoading(true);
    await approveLoanApplication(id);
    alert('Loan application has been approved successfully.');
    getData();
  };

  const handleDecline = async id => {
    await declineLoanApplication(id);
    alert('Loan application has been declined successfully.');
    getData();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'client_id',
        header: 'Client ID',
        size: 50
      },
      {
        accessorKey: 'client_name',
        header: 'Name'
      },
      {
        accessorKey: 'loan_type',
        header: 'Loan Type'
      },
      {
        accessorFn: row => dateFormat(row.created_at),
        header: 'Applied Date',
        size: 50
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => {
      return (
        <Box sx={{ display: 'flex' }}>
          <Tooltip title="Download submitted form">
            <IconButton
              onClick={() => {
                row.original.filenames?.forEach(filename => {
                  getLoanDownloadUrl(filename).then(url => {
                    window.open(url);
                  });
                });
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>

          {row.original.status === 'Pending' ? (
            <>
              <Tooltip title="Approve application">
                <IconButton
                  onClick={() => {
                    if (window.confirm('Click to confirm approve.')) {
                      handleApprove(row.original.id);
                    }
                  }}
                >
                  <Check />
                </IconButton>
              </Tooltip>
              <Tooltip title="Decline application">
                <IconButton
                  onClick={() => {
                    if (window.confirm('Click to confirm decline.')) {
                      handleDecline(row.original.id);
                    }
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </Box>
      );
    }
  });

  return (
    <>
      <div className="dashboard__section section-1">
        <h1 className="dashboard__title">Dashboard</h1>

        <div className="dashboard__info">
          {/* <div className="dashboard__col">
            <h2>2</h2>
            <p>New Membership Applications</p>
          </div> */}

          <div
            className="dashboard__col"
            style={
              {
                // borderLeft: '1px solid #B3BCCE',
                // borderRight: '1px solid #B3BCCE'
              }
            }
          >
            <h2>{data.length}</h2>
            <p>New Loan Applications</p>
          </div>

          <div className="dashboard__col">
            <h2>{dateFormat(user.recent_soa_log.assigned_at)}</h2>
            <p>Last SOA Update</p>
          </div>
        </div>
      </div>

      <div className="dashboard__section section-2">
        <MaterialReactTable table={table} />
      </div>
    </>
  );
};

const MemberDashboard = () => {
  const user = getUserFromSessionStorage();

  const [recentSOA, setRecentSOA] = useState();
  const [balances, setBalances] = useState([]);
  const [loans, setLoans] = useState([]);

  useEffect(() => {
    getMemberDashboardData({ user, date: recentSOA }).then(res => {
      setLoans(res.loans);
      setBalances([
        ...(res.balances || []).filter(x => {
          if (x.type === 'Savings Deposit') return x;
        }),
        ...(res.balances || []).filter(x => {
          if (x.type === 'Time Deposit') return x;
        }),
        ...(res.balances || []).filter(x => {
          if (x.type === 'Share Capital') return x;
        })
      ]);
    });
  }, [recentSOA]);

  return (
    <>
      <div className="dashboard__section section-1">
        <h1 className="dashboard__title">Account Overview</h1>

        <div className="dashboard__info">
          <div className="dashboard__col">
            <h2>{loans?.length}</h2>
            <p>Loan Products</p>
          </div>

          <div
            className="dashboard__col"
            style={
              {
                // borderLeft: '1px solid #B3BCCE',
                // borderRight: '1px solid #B3BCCE'
              }
            }
          >
            <h2>{'1'}</h2>
            <p>Deposit Accounts</p>
          </div>

          <div className="dashboard__col">
            <select
              id="recent-soa-date"
              defaultValue={user.recent_soa_log.assigned_at}
              onChange={event => {
                setRecentSOA(event.target.value);
              }}
            >
              {(user?.soa_dates || [])
                .sort((a, b) => a.localeCompare(b))
                .reverse()
                .map(date => (
                  <option key={date} value={date}>
                    {dateFormat(date)}
                  </option>
                ))}
            </select>
            <p>Recent SOA</p>
          </div>
        </div>
      </div>

      <div className="dashboard__grid">
        {balances?.map(balance => {
          return (
            <div key={balance.id} className="dashboard__grid--item">
              <h2 className="title">{balance.type}</h2>
              <p className="date">As of {dateFormat(balance.updated_at)}</p>
              <p className="amount">{moneyFormat(balance.amount)}</p>
            </div>
          );
        })}

        {loans?.map(loan => {
          return (
            <div key={loan.id} className="dashboard__grid--item">
              <h2 className="title">{loan.type}</h2>
              <p className="date">As of {dateFormat(loan.updated_at)}</p>
              <p className="amount">{moneyFormat(loan.loan_balance)}</p>
            </div>
          );
        })}

        <div className="dashboard__grid--item">
          <h2 className="title">Available benefits here</h2>
        </div>
      </div>
    </>
  );
};

const Dashboard = () => {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');

  return (
    <div className="dashboard">
      {isAdmin ? <AdminDashboard /> : <MemberDashboard />}
    </div>
  );
};

export default Dashboard;
