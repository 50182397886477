import '../../styles/pages/loans.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  createLoanSetting,
  deleteLoanSetting,
  getLoanSettings,
  updateLoanFiles,
  updateLoanSetting
} from './../../core/services/loan';
import { dateFormat } from './../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CsButton from './../../comps/CsButton';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import { Edit, Delete, Close, Download } from '@mui/icons-material';
import {
  deleteForm,
  getFormDownloadUrl,
  uploadForm
} from '../../core/services/loan-application';
const LoanFiles = ({
  id,
  data = [],
  refresh = () => {},
  loading = () => {}
}) => {
  const fileRef = useRef(null);
  const [files, setFiles] = useState([]);
  const handleCreate = async ({ values, table }) => {
    if (files.length && values.filename) {
      table.setCreatingRow(null);
      loading(true);

      await uploadForm({
        filename: values.filename,
        file: files[0]
      });
      await updateLoanFiles({ id, filename: [...data, values] });

      refresh();
    } else {
      alert('Please select a file to upload and provide a filename');
    }
  };

  const handleUpdate = async ({ row, values, table }) => {
    if (files.length && values.filename) {
      table.setCreatingRow(null);
      loading(true);

      await deleteForm({ filename: row.original.filename });
      await uploadForm({
        filename: values.filename,
        file: files[0]
      });
      await updateLoanFiles({
        id,
        filename: [
          ...data.map(x => {
            if (x.filename === row.original.filename) {
              return {
                filename: values.filename
              };
            } else return x;
          })
        ]
      });

      refresh();
    } else {
      alert('Please select a file to upload and provide a filename');
    }
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Click confirm to delete?`)) {
      table.setCreatingRow(null);
      loading(true);

      await deleteForm({ filename: row.original.filename });
      await updateLoanFiles({
        id,
        filename: [...data.filter(x => x.filename !== row.original.filename)]
      });

      refresh();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'filename',
        header: 'File Name',
        muiEditTextFieldProps: {
          required: true
        }
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableTopToolbar: false,
    enableBottomToolbar: false,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Download">
          <IconButton
            onClick={() => {
              getFormDownloadUrl(row.original.filename).then(url => {
                window.open(url);
              });
            }}
          >
            <Download />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Loan File</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
          <CsButton
            label="Upload File"
            onClick={() => fileRef.current.click()}
          />
          {files?.map(file => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              key={file.name}
            >
              <p>{file.name}</p>

              <IconButton
                size="small"
                className="close-btn"
                onClick={() => {
                  const filteredFiles = files.filter(x => x.name !== file.name);
                  setFiles(filteredFiles);
                }}
              >
                <Close />
              </IconButton>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Loan File</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
          <CsButton
            label="Change File"
            onClick={() => fileRef.current.click()}
          />
          {files?.map(file => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              key={file.name}
            >
              <p>{file.name}</p>

              <IconButton
                size="small"
                className="close-btn"
                onClick={() => {
                  const filteredFiles = files.filter(x => x.name !== file.name);
                  setFiles(filteredFiles);
                }}
              >
                <Close />
              </IconButton>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowCancel: () => {
      setFiles([]);
    },
    onEditingRowCancel: () => {
      setFiles([]);
    },
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        onChange={event => {
          const file = event.target.files[0];
          if (!file) return;
          setFiles(prev => [...prev, file]);
        }}
        hidden
      />
      <CsButton
        label="Add Loan File"
        onClick={() => {
          table.setCreatingRow(true);
        }}
      />
      <br />
      <MaterialReactTable table={table} />
    </>
  );
};

const Loans = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getLoanSettings().then(res => {
      setData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createLoanSetting(values);
    getData();
  };

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateLoanSetting({ ...values, id: row.original.id });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (
      window.confirm(`Are you sure you want to delete ${row.original.field}?`)
    ) {
      setIsLoading(true);
      await deleteLoanSetting(row.original);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'field',
        header: 'Name'
      },
      {
        accessorKey: 'instructions',
        header: 'Instructions',
        muiEditTextFieldProps: {
          label: 'Set instructions here...',
          variant: 'outlined',
          multiline: true,
          minRows: 7
        }
      },
      {
        accessorKey: 'created_at',
        accessorFn: row => dateFormat(row.created_at),
        header: 'Created Date',
        enableEditing: false
      },
      {
        accessorKey: 'updated_at',
        accessorFn: row => dateFormat(row.updated_at),
        header: 'Updated Date',
        enableEditing: false
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableEditing: false
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading,
      columnVisibility: {
        instructions: false
      }
    },
    renderDetailPanel: ({ row }) => {
      const data = Array.isArray(row.original.filename)
        ? row.original.filename.map(f => ({ filename: f }))
        : [];

      return (
        <LoanFiles
          id={row.original.id}
          data={data}
          refresh={() => {
            getData();
          }}
          loading={value => {
            setIsLoading(value);
          }}
        />
      );
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          table.setCreatingRow(
            createRow(table, {
              created_at: new Date(),
              updated_at: new Date(),
              status: 'Active'
            })
          );
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create New Loan Product</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Loan Product</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <div className="loans">
      <div className="loans__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default Loans;
