import '../../styles/pages/calculators.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
  createCalculatorSetting,
  deleteCalculatorSetting,
  getCalculatorSettings,
  updateCalculatorSetting,
  updateLoanRateSetting
} from './../../core/services/calculator';
import { dateFormat } from './../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CsButton from './../../comps/CsButton';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const LoanRates = ({
  id,
  data = [],
  refresh = () => {},
  loading = () => {}
}) => {
  const handleCreate = async ({ values, table }) => {
    const loan_rate = [
      ...data.map(x => ({
        rate: x.rate,
        range: [x.start_range, x.end_range]
      })),
      {
        rate: parseFloat(values.rate),
        range: [parseInt(values.start_range), parseInt(values.end_range)]
      }
    ];

    table.setCreatingRow(null);
    loading(true);
    await updateLoanRateSetting({ id, loan_rate });
    refresh();
  };

  const handleUpdate = async ({ row, values, table }) => {
    const updatedData = data.map(x => {
      if (x.id === row.original.id) {
        return {
          id: row.original.id,
          rate: values.rate,
          start_range: values.start_range,
          end_range: values.end_range
        };
      } else return x;
    });

    const loan_rate = updatedData.map(x => ({
      rate: x.rate,
      range: [x.start_range, x.end_range]
    }));

    table.setCreatingRow(null);
    loading(true);
    await updateLoanRateSetting({ id, loan_rate });
    refresh();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Click confirm to delete?`)) {
      const updatedData = data.filter(x => x.id !== row.original.id);

      const loan_rate = updatedData.map(x => ({
        rate: x.rate,
        range: [x.start_range, x.end_range]
      }));

      table.setCreatingRow(null);
      loading(true);
      await updateLoanRateSetting({ id, loan_rate });
      refresh();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'rate',
        header: 'Interest Rate (Decimal)',
        muiEditTextFieldProps: {
          required: true
        }
      },
      {
        accessorKey: 'start_range',
        header: 'Term Start Range (Year)'
      },
      {
        accessorKey: 'end_range',
        header: 'Term End Range (Year)'
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableTopToolbar: false,
    enableBottomToolbar: false,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Loan Rate</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Loan Rate</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <>
      <CsButton
        label="Add Loan Rate"
        onClick={() => {
          table.setCreatingRow(true);
        }}
      />
      <br />
      <MaterialReactTable table={table} />
    </>
  );
};

const Calculators = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getCalculatorSettings().then(res => {
      setData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createCalculatorSetting(values);
    getData();
  };

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateCalculatorSetting({
      ...values,
      id: row.original.id,
      loan_rate: values?.loan_rate || row.original.loan_rate
    });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (
      window.confirm(`Are you sure you want to delete ${row.original.field}?`)
    ) {
      setIsLoading(true);
      await deleteCalculatorSetting(row.original.id);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'field',
        header: 'Field'
      },
      {
        accessorKey: 'desc',
        header: 'Description',
        size: 400
      },
      {
        accessorKey: 'max_amount',
        header: 'Max Amount'
      },
      {
        accessorKey: 'max_amount_text',
        header: 'Max Amount Text'
      },
      {
        accessorKey: 'max_term',
        header: 'Max Term (Years)'
      },
      {
        accessorKey: 'created_at',
        accessorFn: row => dateFormat(row.created_at),
        header: 'Created Date',
        enableEditing: false
      },
      {
        accessorKey: 'updated_at',
        accessorFn: row => dateFormat(row.created_at),
        header: 'Updated Date',
        enableEditing: false
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableEditing: false
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderDetailPanel: ({ row }) => {
      const data = row.original.loan_rate?.map((x, index) => ({
        id: index + 1,
        rate: x.rate,
        start_range: parseInt(x.range[0]),
        end_range: parseInt(x.range[1])
      }));

      return (
        <LoanRates
          id={row.original.id}
          data={data}
          refresh={() => {
            getData();
          }}
          loading={value => {
            setIsLoading(value);
          }}
        />
      );
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          table.setCreatingRow(
            createRow(table, {
              created_at: new Date(),
              updated_at: new Date(),
              status: 'Active'
            })
          );
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create New Calculator Setting</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Calculator Setting</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate
  });

  return (
    <div className="calculators">
      <div className="calculators__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default Calculators;
