import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  verifyBeforeUpdateEmail
} from 'firebase/auth';

export const login = async data => {
  try {
    const response = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const createAuthentication = async data => {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    await updateDoc(doc(db, 'clients', data.id), {
      authenticated: true,
      password: null,
      updated_at: new Date().getTime()
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const logout = () => {
  sessionStorage.removeItem('dsebspcc_user');
  sessionStorage.removeItem('dsebspcc_member_loans');
  sessionStorage.removeItem('dsebspcc_member_balances');

  auth.signOut();
};

export const getUser = async user => {
  try {
    let result = null;
    const clientQuery = query(
      collection(db, 'clients'),
      where('email', '==', user.email),
      limit(1)
    );
    const clientQuerySnap = await getDocs(clientQuery);

    clientQuerySnap.forEach(doc => {
      if (doc.exists()) {
        result = { ...doc.data(), id: doc.id, role: doc.data().client_type };
      }
    });

    if (result) {
      return result;
    }

    const staffQuery = query(
      collection(db, 'staffs'),
      where('email', '==', user.email),
      limit(1)
    );
    const staffQuerySnap = await getDocs(staffQuery);

    staffQuerySnap.forEach(doc => {
      if (doc.exists()) {
        result = { ...doc.data(), id: doc.id };
      }
    });

    return result;
  } catch (error) {
    return error;
  }
};

export const getUserFromSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem('dsebspcc_user'));
};

export const logUser = async ({ log_type, user }) => {
  try {
    return await addDoc(collection(db, 'user_online_logs'), {
      executed_at: new Date().getTime(),
      log_type: log_type,
      staff_id: user?.staff_id ?? '',
      client_id: user?.client_id ?? '',
      user_name: [user.first_name, user.last_name].join(' ')
    });
  } catch (error) {
    return error;
  }
};

export const updateUserPassword = async (currPassword, newPassword) => {
  try {
    const resLogin = await login({
      email: auth.currentUser.email,
      password: currPassword
    });

    if (resLogin.code === 'auth/invalid-credential') {
      throw resLogin;
    }

    await updatePassword(auth.currentUser, newPassword);

    return { success: true };
  } catch (error) {
    if (error.code === 'auth/invalid-credential') {
      alert(error.message);
    } else {
      alert(error);
    }

    return { success: false };
  }
};

export const updateUserEmail = async (password, newEmail) => {
  try {
    const resLogin = await login({
      email: auth.currentUser.email,
      password: password
    });

    if (resLogin.code === 'auth/invalid-credential') {
      throw resLogin;
    }

    await verifyBeforeUpdateEmail(auth.currentUser, newEmail);
    const user = getUserFromSessionStorage();

    await updateDoc(doc(db, 'clients', user.id), {
      email: newEmail,
      updated_at: new Date().getTime()
    });

    return { success: true };
  } catch (error) {
    if (error.code === 'auth/invalid-credential') {
      alert(error.message);
    } else {
      alert(error);
    }

    return { success: false };
  }
};

export const forgotPassword = async email => {
  try {
    const res = await sendPasswordResetEmail(auth, email);

    return { success: true, res };
  } catch (error) {
    if (error.code === 'auth/invalid-credential') {
      alert(error.message);
    } else {
      alert(error);
    }

    return { success: false };
  }
};

export const emailTemporaryPassword = async email => {
  try {
    const user = await getUser({ email });

    if (user) {
      await addDoc(collection(db, 'auto_send_email'), {
        type: 'temporary_password',
        to: [email],
        message: {
          subject: `Temporary Password`,
          html: `<p style="color: black">
            Good day!
            <br/><br/>
            Your temporary password is: <b>${user?.password}</b>.
            <br/><br/>
            DO NOT SHARE to anyone. Thank you.
            <br/><br/>
            <i>This is system generated. Do not reply to this email.</i>
            <br/>
            <i>Should you encounter any problems or for any concerns, feel free to reply to this email or contact us at systemadmin@dsebspcc.com | DL. 8708-7597 Local. 2992/2993 Carmelo Z. Lagrason, Justin Cyril L. Espiritu</i>  
          </p>`
        }
      });
    } else return { success: false };

    return { success: true };
  } catch (error) {
    return { success: false };
  }
};
