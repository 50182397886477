import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Loans from './Loans';
import Calculators from './Calculators';

const ManageProducts = () => {
  return (
    <Routes>
      <Route path="/loans" element={<Loans />} />
      <Route path="/calculators" element={<Calculators />} />
    </Routes>
  );
};

export default ManageProducts;
