import '../../styles/be-a-member.scss';
import { validateCode } from '../../core/services/be-a-member';
import CsButton from '../../comps/CsButton';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import CsInput from './../../comps/CsInput';
import { useEffect, useState } from 'react';
import {
  getGuideListData,
  getQuestionListData,
  getVideoSeminar
} from '../../core/services/pre-membership';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material';

const Step1 = ({ next }) => {
  const [videoSeminar, setVideoSeminar] = useState();
  useEffect(() => {
    getVideoSeminar().then(url => {
      setVideoSeminar(url);
    });
  }, []);

  return (
    <>
      <h4 className="header">DSEBSPCC Pre-Membership Seminar</h4>

      <div className="step-1">
        <div className="media-container">
          <video src={videoSeminar} controls style={{ objectFit: 'contain' }}>
            <source src={videoSeminar} type="video/webm" />
            <source src={videoSeminar} type="video/mp4" />
          </video>
        </div>

        <div className="actions">
          <p className="done-watching">
            Done watching? you can proceed directly.
          </p>
          <CsButton label="Next Step" classContainer="next" onClick={next} />
        </div>
      </div>
    </>
  );
};

const Step2 = ({ next }) => {
  const [data, setData] = useState([]);
  const [score, setScore] = useState(0);
  const [passed, setPassed] = useState(false);
  const [answers, setAnswers] = useState({ correct: {}, user: {} });

  const getData = () => {
    getQuestionListData().then(res => {
      setData(res.questions.sort((a, b) => a.order.localeCompare(b.order)));
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let correct = {};
    let user = {};

    data.forEach(item => {
      correct = { ...correct, [item.created_at]: item.answer };
      user = { ...user, [item.created_at]: '' };
    });

    setAnswers({ correct, user });
  }, [data]);

  useEffect(() => {
    if (Object.keys(answers.correct).length) {
      let count = 0;
      Object.keys(answers.correct).forEach(key => {
        if (answers.correct[key] === answers.user[key]) {
          count++;
        }
      });
      setScore(count);
    }
  }, [answers]);

  return (
    <>
      <h4 className="header">
        Take a quick and easy quiz. All items were discussed in the video.
      </h4>

      <div className="step-2">
        {data.map(item => {
          return (
            <div key={item.created_at} className="item">
              <FormControl>
                <FormLabel id={item.created_at}>{item.question}</FormLabel>
                <RadioGroup
                  aria-labelledby={item.created_at}
                  name="radio-buttons-group"
                >
                  {item.options.map(option => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                      onClick={() => {
                        const userAnswer = answers.user;
                        userAnswer[item.created_at] = option;

                        setAnswers({
                          correct: answers.correct,
                          user: userAnswer
                        });
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          );
        })}

        <div className="actions">
          {passed ? (
            <CsButton label="Next Step" classContainer="next" onClick={next} />
          ) : (
            <CsButton
              label="Submit"
              classContainer="submit"
              onClick={() => {
                if (score > data.length * 0.75) {
                  setPassed(score > data.length * 0.75);
                  alert(
                    `Congratulations! You have scored ${score}/${data.length}. Please click the Next Step button below to proceed.`
                  );
                } else {
                  alert(
                    'Sorry, you have not met the required score to proceed to the next step. No worries! You can watch the video again to improve your score.'
                  );
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const Step3 = ({ next }) => {
  const [data, setData] = useState([]);

  const getData = () => {
    getGuideListData().then(res => {
      setData(res.guides.sort((a, b) => a.order.localeCompare(b.order)));
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h4 className="header">
        Congratulations on reaching the final step! Follow our guide below to
        become a member.
      </h4>
      <div className="step-3">
        {data.map(item => {
          return <p key={item.created_at}>{item.instruction}</p>;
        })}
      </div>

      <div className="actions">
        <CsButton label="Finish" classContainer="next" onClick={next} />
      </div>
    </>
  );
};

const BeAMember = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [step, setStep] = useState(0);

  const handleSubmitCode = () => {
    validateCode(code).then(res => {
      if (res.success) {
        setStep(1);
      } else {
        alert('This code is invalid.');
      }
    });
  };

  const steps = {
    0: (
      <div className="invitation">
        <CsInput
          value={code}
          classContainer="input"
          placeholder="Enter invitation code"
          onChange={value => setCode(value)}
        />
        <CsButton
          classContainer="submit"
          label="Submit"
          onClick={handleSubmitCode}
        />
      </div>
    ),
    1: (
      <Step1
        next={() => {
          setStep(2);
        }}
      />
    ),
    2: (
      <Step2
        next={() => {
          setStep(3);
        }}
      />
    ),
    3: (
      <Step3
        next={() => {
          navigate('/login');
        }}
      />
    )
  };

  return (
    <div className="container">
      <div className="be-a-member">
        <div className="header">
          <CsButton
            label=""
            icon={faArrowLeftLong}
            onClick={() => {
              if (step === 0) {
                navigate('/login');
              } else {
                setStep(prev => prev - 1);
              }
            }}
          />
        </div>

        {steps[step]}
      </div>
    </div>
  );
};

export default BeAMember;
