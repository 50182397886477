import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

export const getStaffsListData = async () => {
  try {
    const staffs = await getDocs(collection(db, 'staffs'));

    let staffsData = [];

    staffs.forEach(doc => {
      staffsData = [...staffsData, { ...doc.data(), id: doc.id }];
    });

    return { staffs: staffsData };
  } catch (error) {
    return error;
  }
};

export const createStaff = async data => {
  try {
    await createUserWithEmailAndPassword(auth, data.email, data.password);

    const res = await addDoc(collection(db, 'staffs'), {
      staff_id: data.staff_id,
      role: 'admin',
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      contact: data.contact,
      gender: data.gender,
      status: data.status,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const updateStaffDetails = async data => {
  try {
    const res = await updateDoc(doc(db, 'staffs', data.id), {
      first_name: data.first_name,
      last_name: data.last_name,
      contact: data.contact,
      gender: data.gender,
      status: data.status,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};
