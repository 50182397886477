import { useState } from 'react';
import './../../styles/pages/download-soa.scss';
import React, { useEffect } from 'react';
import { dateFormat } from '../../core/utils/dateFormat';
import { moneyFormat } from '../../core/utils/moneyFormat';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { useLocation } from 'react-router-dom';
import { downloadSOA } from '../../core/services/soa';

const DownloadSOA = () => {
  const { state } = useLocation();

  const [updatedOn, setUpdatedOn] = useState();
  const [fullName, setFullName] = useState();
  const [clientID, setClientID] = useState();
  const [employeeID, setEmployeeID] = useState();
  const [memberType, setMemberType] = useState();
  const [status, setStatus] = useState();
  const [balances, setBalances] = useState([]);
  const [loans, setLoans] = useState([]);

  useEffect(() => {
    if (window !== undefined) {
      const user = getUserFromSessionStorage();

      setFullName(
        [user?.first_name, user?.middle_name ?? '', user?.last_name].join(' ')
      );
      setClientID(user?.client_id);
      setEmployeeID(user?.staff_id);
      setMemberType(user?.role);
      setStatus(user?.status ?? 'Active');
      downloadSOA({ user, date: state.date }).then(res => {
        setUpdatedOn(dateFormat(state.date ?? user.recent_soa_log.assigned_at));
        setLoans(res.loans);
        setBalances(res.balances);
        setTimeout(() => {
          window.print();
        });
      });
    }
  }, []);

  return (
    <div className="download-soa">
      <div className="header">
        <img src="/logo-icon.png" alt="logo" className="logo" />
        <div className="text">
          <p style={{ marginTop: 5 }}>DSE (BSP) CREDIT COOPERATIVE</p>
          <p>DSEBSPCC</p>
          <p style={{ marginTop: 15 }}>
            RM 303-A EDPC BLDG. BSP COMPLEX, MALATE, MANILA
          </p>
          <p>TIN: 000-757-851-000</p>
          <p>(02)8-789-7011*Loc. 2992/2993</p>
        </div>
      </div>

      <div className="section-1">
        <p className="title">Statement of Account</p>

        <div className="account-details">
          <p>Account Details</p>
          <table>
            <tbody>
              <tr>
                <td>Updated on: {updatedOn}</td>
                <td>Full Name: {fullName}</td>
              </tr>
              <tr>
                <td>Client ID: {clientID}</td>
                <td>Member Type: {memberType}</td>
              </tr>
              <tr>
                <td>Employee ID: {employeeID}</td>
                <td>Status: {status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="section-2">
        <p className="title">SUMMARY OF ACCOUNTS</p>

        <table>
          <tbody>
            <tr
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black'
              }}
            >
              <td style={{ borderRight: '1px solid black' }}>
                <b>Deposits</b>
              </td>
              <td style={{ paddingLeft: 10 }}>Actual Balance</td>
            </tr>
            <tr>
              <td style={{ borderRight: '1px solid black' }}>Savings</td>
              <td style={{ paddingLeft: 10 }}>
                {moneyFormat(
                  balances?.filter(x => x.type === 'Savings Deposit')[0]?.amount
                )}
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: '1px solid black' }}>Time Deposit</td>
              <td style={{ paddingLeft: 10 }}>
                {moneyFormat(
                  balances?.filter(x => x.type === 'Time Deposit')[0]?.amount
                )}
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: '1px solid black' }}>Share Capital</td>
              <td style={{ paddingLeft: 10 }}>
                {moneyFormat(
                  balances?.filter(x => x.type === 'Share Capital')[0]?.amount
                )}
              </td>
            </tr>
            <tr
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black'
              }}
            >
              <td style={{ borderRight: '1px solid black' }}>Total</td>
              <td style={{ paddingLeft: 10 }}>
                {moneyFormat(
                  balances?.reduce(
                    (acc, balance) => parseFloat(balance.amount) + acc,
                    0
                  )
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="section-3">
        <table>
          <tbody>
            <tr>
              <td style={{ borderTop: '1px solid black' }}>
                <b>Loan Receivable</b>
              </td>
              <td style={{ border: '1px solid black' }}>Type</td>
              <td style={{ border: '1px solid black' }}>Principal Amount</td>
              <td style={{ border: '1px solid black' }}>Term (Months)</td>
              <td style={{ border: '1px solid black' }}>Rate (%)</td>
              <td style={{ border: '1px solid black' }}>Total Due</td>
              <td style={{ border: '1px solid black', borderRight: 'none' }}>
                Balance
              </td>
            </tr>

            {loans?.map(loan => {
              return (
                <tr key={loan.id}>
                  <td></td>
                  <td style={{ border: '1px solid black' }}>{loan.type}</td>
                  <td style={{ border: '1px solid black' }}>
                    {moneyFormat(loan.amount)}
                  </td>
                  <td style={{ border: '1px solid black' }}>{loan.term}</td>
                  <td style={{ border: '1px solid black' }}>{loan.rate}</td>
                  <td style={{ border: '1px solid black' }}>
                    {moneyFormat(loan.monthly_amortization)}
                  </td>
                  <td
                    style={{ border: '1px solid black', borderRight: 'none' }}
                  >
                    {moneyFormat(loan.loan_balance ?? 0)}
                  </td>
                </tr>
              );
            })}

            <tr
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black'
              }}
            >
              <td>
                <b>Total</b>
              </td>
              <td></td>
              <td style={{ borderLeft: '1px solid black' }}>
                {moneyFormat(
                  loans?.reduce((acc, loan) => parseFloat(loan.amount) + acc, 0)
                )}
              </td>
              <td style={{ borderLeft: '1px solid black' }}>-</td>
              <td style={{ borderLeft: '1px solid black' }}>-</td>
              <td style={{ borderLeft: '1px solid black' }}>
                {moneyFormat(
                  loans?.reduce(
                    (acc, loan) => parseFloat(loan.monthly_amortization) + acc,
                    0
                  )
                )}
              </td>
              <td style={{ borderLeft: '1px solid black' }}>
                {moneyFormat(
                  loans?.reduce(
                    (acc, loan) => parseFloat(loan.loan_balance) + acc,
                    0
                  ) || 0
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <br />
      <br />
      <p style={{ fontStyle: 'italic' }}>
        This is a system generated Statement of Account. Should you have any
        questions, contact us at (02)8-789-7011*Loc. 2992/2993 or via Email at
        systemadmin@dsebspcc.com
      </p>
    </div>
  );
};

export default DownloadSOA;
