import './../../styles/pages/upload-soas.scss';
import React, { useState } from 'react';
import CsButton from '../../comps/CsButton';
import { deleteData } from '../../core/services/soa';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { dateFormat } from '../../core/utils/dateFormat';

const UploadSOAs = () => {
  const user = getUserFromSessionStorage();
  const [tableToBeDeleted, setTableToBeDeleted] = useState('');
  const [dateToBeDeleted, setDateToBeDeleted] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteSOAData = () => {
    if (window.confirm('Proceed to delete? This can not be undone.')) {
      setIsLoading(true);

      deleteData({ table: tableToBeDeleted, date: dateToBeDeleted })
        .then(res => {
          alert('SOAs are now updated!');
        })
        .catch(err => {
          alert('Something went wrong.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="upload-soas">
      <div className="upload-soas__grid">
        <div className="upload-soas__grid--item">
          <p style={{ color: 'tomato' }}>SELECT SOA TO DELETE</p>
          <div style={{ display: 'flex', gap: 10 }}>
            <select
              id="recent-soa-table"
              onChange={event => {
                setTableToBeDeleted(event.target.value);
              }}
            >
              <option value=""></option>
              <option value="balances">Savings</option>
              <option value="loans">Loans</option>
            </select>
            <select
              id="recent-soa-date"
              onChange={event => {
                setDateToBeDeleted(event.target.value);
              }}
            >
              <option value=""></option>
              {(user?.soa_dates || []).sort((a, b) => a.localeCompare(b)).reverse().map(date => (
                <option key={date} value={date}>
                  {dateFormat(date)}
                </option>
              ))}
            </select>
          </div>

          {isLoading ? (
            <span>Deleting... Please do not reload the page.</span>
          ) : null}

          {tableToBeDeleted && dateToBeDeleted && !isLoading && (
            <CsButton label="Delete Batch" onClick={handleDeleteSOAData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadSOAs;
