import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { deleteObject, ref } from 'firebase/storage';

export const getMemberLoanData = async data => {
  try {
    // const l = sessionStorage.getItem('dsebspcc_member_loans');

    // if (l && !data.date) {
    //   return { loans: JSON.parse(l) };
    // }

    const loans = await getDocs(
      query(
        collection(db, 'loans'),
        where('client_id', '==', data.user.client_id),
        where(
          'created_at',
          '==',
          data.date ?? data.user.recent_soa_log.assigned_at
        )
      )
    );

    let loansData = [];

    loans.forEach(doc => {
      loansData = [...loansData, { ...doc.data(), id: doc.id }];
    });

    // sessionStorage.setItem('dsebspcc_member_loans', JSON.stringify(loansData));

    return { loans: loansData };
  } catch (error) {
    return error;
  }
};

export const getLoanSettings = async () => {
  try {
    const loanSettings = await getDocs(collection(db, 'loan_settings'));

    let data = [];

    loanSettings.forEach(doc => {
      data = [...data, { ...doc.data(), id: doc.id }];
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const createLoanSetting = async data => {
  try {
    const res = await addDoc(collection(db, 'loan_settings'), {
      field: data.field,
      instructions: data.instructions,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: 'Active'
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateLoanSetting = async data => {
  try {
    const res = await updateDoc(doc(db, 'loan_settings', data.id), {
      field: data.field,
      instructions: data.instructions,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteLoanSetting = async data => {
  try {
    const res = await deleteDoc(doc(db, 'loan_settings', data.id));

    await Promise.all(
      data.filename?.map(filename =>
        deleteObject(ref(storage, 'forms/' + filename))
      ) || []
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const updateLoanFiles = async data => {
  try {
    const res = await updateDoc(doc(db, 'loan_settings', data.id), {
      filename: data.filename?.map(f => f.filename) || [],
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};
