import './../../styles/pages/member-loans.scss';
import React, { useEffect, useState } from 'react';
import { moneyFormat } from '../../core/utils/moneyFormat';
import { getMemberLoanData } from '../../core/services/loan';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { dateFormat } from '../../core/utils/dateFormat';

const MemberLoans = () => {
  const user = getUserFromSessionStorage();

  const [recentSOA, setRecentSOA] = useState();
  const [loans, setLoans] = useState([]);

  useEffect(() => {
    if (window !== undefined) {
      getMemberLoanData({ user, date: recentSOA }).then(res => {
        setLoans(res.loans);
      });
    }
  }, [recentSOA]);

  return (
    <div className="member-loans">
      <div className="member-loans__section section-1">
        <h1 className="member-loans__title">Statement of Account for Loans</h1>

        <div className="member-loans__info">
          <div className="member-loans__col">
            <h2>{loans?.length}</h2>
            <p>Loan Products</p>
          </div>

          <div
            className="member-loans__col"
            style={{
              // borderRight: '1px solid #B3BCCE',
              // borderLeft: '1px solid #B3BCCE'
            }}
          >
            <h2>
              {moneyFormat(
                loans?.reduce(
                  (acc, loan) => parseFloat(loan.loan_balance) + acc,
                  0
                ) || 0
              )}
            </h2>
            <p>Total Loan Balance</p>
          </div>

          <div className="member-loans__col">
            <select
              id="recent-soa-date"
              defaultValue={user.recent_soa_log.assigned_at}
              onChange={event => {
                setRecentSOA(event.target.value);
              }}
            >
              {(user?.soa_dates || []).sort((a, b) => a.localeCompare(b)).reverse().map(date => (
                <option key={date} value={date}>
                  {dateFormat(date)}
                </option>
              ))}
            </select>
            <p>Recent SOA</p>
          </div>
        </div>
      </div>

      <div className="member-loans__grid">
        {loans?.map(loan => (
          <div key={loan.id} className="member-loans__grid--item">
            <h2 className="title">{loan.type}</h2>
            <div className="highlighted">
              <p>Loan Type: {loan.type}</p>
            </div>
            <div>
              <p>Loan Balance: {moneyFormat(loan.loan_balance)}</p>
            </div>

            <div className="highlighted">
              <p>Term: {loan.term + ' ' + loan.term_unit}</p>
            </div>
            <div>
              <p>Interest Rate: {parseFloat(loan.rate) + '%'}</p>
            </div>

            <div className="highlighted-2">
              <p>
                Monthly Amortization: {moneyFormat(loan.monthly_amortization)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberLoans;
