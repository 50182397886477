import { DateTime } from 'luxon';

export const dateFormat = date => {
  const res = DateTime.fromJSDate(new Date(date)).toFormat('LLL dd, yyyy');

  return res === 'Invalid DateTime' ? '' : res;
};

export const dateInputFormat = (date = new Date()) => {
  const res = DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd');

  return res === 'Invalid DateTime' ? '' : res;
};
