import './../../styles/pages/loan-application.scss';
import CsSelect from './../../comps/CsSelect';
import CsButton from './../../comps/CsButton';
import React, { useEffect, useRef, useState } from 'react';
import {
  getFormDownloadUrl,
  submitLoanApplication
} from '../../core/services/loan-application';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { getLoanSettings } from './../../core/services/loan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const LoanApplication = () => {
  const [tester, setTester] = useState(false);
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const fileInput = useRef(null);

  useEffect(() => {
    getLoanSettings().then(res => {
      const sorted = res.sort((a, b) => a.field.localeCompare(b.field));

      setData(sorted);
    });
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      const user = getUserFromSessionStorage();
      const isTester = [
        '17994',
        '25214',
        '16981',
        '24622',
        '16971',
        '25214'
      ].includes(user?.client_id);

      setTester(isTester);
    }
  }, []);

  const handleOnSelect = option => {
    setSelectedProduct(option);
  };

  const handleSubmitApplication = () => {
    if (files?.[0]) {
      const user = getUserFromSessionStorage();
      submitLoanApplication({
        user,
        files,
        details: selectedProduct
      })
        .then(res => {
          alert(
            'Loan application has been submitted. Our Loans Team will reach out to you for the next steps. Thank you!'
          );
        })
        .finally(() => {
          setFiles([]);
          setSelectedProduct(null);
        });
    }
  };

  return tester ? (
    <div className="loan-application">
      <div className="loan-application__section section-1">
        <CsSelect
          keyRef="field"
          displayRef="field"
          label="Select Loan"
          options={data}
          onSelect={handleOnSelect}
        />
      </div>

      {selectedProduct ? (
        <div className="loan-application__section section-2">
          <div className="instructions">
            {/* <p>
              Instructions for <b>{selectedProduct.field}</b> application.
            </p>
            <ul>
              <li>1. Download and print the loan application form below.</li>
              <li>2. Fill up the form.</li>
              <li>
                3.
                {selectedProduct.value === 'real_property_loan'
                  ? ' Scan the form and submit the necessary documentary attachments below.'
                  : ' Scan the form and submit via “Submit Loan Application”.'}
              </li>
            </ul> */}
            <pre>{selectedProduct.instructions}</pre>
          </div>
          <div className="col">
            <div className="download-files">
              {(Array.isArray(selectedProduct?.filename)
                ? selectedProduct?.filename
                : []
              )?.map(file => (
                <div key={file}>
                  <CsButton
                    icon={faDownload}
                    label={file}
                    onClick={() => {
                      getFormDownloadUrl(file).then(url => {
                        window.open(url, '_blank');
                      });
                    }}
                  />
                </div>
              ))}
            </div>
            {selectedProduct.value !== 'real_property_loan' ? (
              <div>
                <input
                  ref={fileInput}
                  type="file"
                  onChange={event => {
                    const { files } = event.target;

                    setFiles([...files]);
                  }}
                  multiple
                  hidden
                />
                <CsButton
                  label="Upload Loan Application"
                  onClick={() => {
                    fileInput.current.click();
                  }}
                />
                <p>Upload file in PDF/JPG/PNG formats</p>
              </div>
            ) : null}
          </div>

          {files?.length ? (
            <div className="selected-files">
              {files?.map(file => (
                <div className="item" key={file.name}>
                  <p>
                    {file.name} - {`${(file.size / 1024).toFixed(2)} KB`}
                  </p>

                  <IconButton
                    size="small"
                    className="close-btn"
                    onClick={() => {
                      const filteredFiles = files.filter(
                        x => x.name !== file.name
                      );
                      setFiles(filteredFiles);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              ))}

              <CsButton
                className="submit-btn"
                label="Submit Loan Application"
                mode="success"
                onClick={handleSubmitApplication}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {selectedProduct?.value === 'real_property_loan' ? (
        <div className="loan-application__section section-3">
          <p>Upload documents here:</p>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>Application form with Promissory Note</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>Marriage Certificate, if Married</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Electronic copy of TCT/OCT/CCT under the name of thhe borrower/s,
              if applicable (all pages including blank pages)
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Updated copy of Tax Declaration (House and Lot_ in the name of the
              borrower/s, if applicable
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Real Estate Tax Receipt/Tax Clearance (Photocopy) in the name of
              the borrower/s for the current year
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Loan and Mortgage Agreement duly regisred with the Registry of
              Deeds with Orignial RD stamp
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Deed of Absolute Sale or Deed of Contract to Buy/Sell or option to
              Buy/Sell the real estate property
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Lot Plan with Location/Vicinity Map certified by a licensed
              Geodetic Engineer
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>Photos of the real estate property</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              Deed of Undertaking to cause the transfer of the TCT/OCT/CCT to
              the borrowing member and the registration of the REM with the
              Registry of Deeds
            </p>
          </div>

          <p>For refinancing of an existing real estate or home loan:</p>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>(i) current statement of Account</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (ii) Official Receipt for amortization payments made or
              Passbook/Bank Statement where amortization payment is debited (at
              least 6 months)
            </p>
          </div>

          <p>For construction, renovation and/or improvements:</p>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (i) Bills of Materials/Specifications/Cost Estimates duly signed
              by a licensed Civil Engineer or Architect
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>(ii) Scope of Work (for renovation)</p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (iii) Building Plan (complete set) duly signed by a licensed
              Engineer or Architect
            </p>
          </div>

          <div className="group">
            <CsButton label="Upload" className="button" />
            <p>
              (iv) Building Permit and other relevant permits as may be required
            </p>
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <h1>
      <FontAwesomeIcon icon={faScrewdriverWrench} /> Under construction
    </h1>
  );
};

export default LoanApplication;
