import './../../styles/pages/resources.scss';
import { Delete, Edit, Tv } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CsButton from '../../comps/CsButton';
import {
  faImage,
  faPaperPlane,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import {
  createResource,
  deleteResource,
  getResourceDownloadUrl,
  getResourcesListData,
  updateResource
} from '../../core/services/resources';
import { dateFormat } from '../../core/utils/dateFormat';
import { getUserFromSessionStorage } from '../../core/services/auth';

const AdminResources = () => {
  const [data, setData] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');

  const mediaRef = useRef(null);
  const [mediaPreview, setMediaPreview] = useState('');

  const checkImage = file => {
    const imageExtensions = ['.gif', '.jpg', '.jpeg', '.png'];
    let flag = false;
    imageExtensions.forEach(e => {
      if (file.includes(e)) {
        flag = true;
      }
    });
    setIsImage(flag);
  };

  const handleMediaPreview = event => {
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    checkImage(event.target.files[0].name);
    setMediaPreview(objectUrl);
  };

  const getData = () => {
    getResourcesListData().then(res => {
      setData(res.resources);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async ({ table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    const res = await createResource({
      title,
      details,
      file: mediaRef.current.files[0]
    });
    if (res.error) {
      alert(res.message);
    }
    getData();
  };
  const handleUpdate = async ({ row, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);

    await updateResource({
      title,
      details,
      id: row.original.id,
      filename: row.original.filename,
      file: mediaRef.current.files[0]
    });
    getData();
  };

  const openDeleteConfirmModal = async row => {
    if (window.confirm(`Are you sure you want to delete this video?`)) {
      setIsLoading(true);
      await deleteResource(row.original);
      getData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'created_at',
        accessorFn: row => dateFormat(row.created_at),
        header: 'Added On',
        size: 50,
        enableEditing: false
      },
      {
        accessorKey: 'creator_name',
        header: 'Added By',
        size: 50,
        enableEditing: false
      },
      {
        accessorKey: 'title',
        header: 'Title',
        size: 200
      },
      {
        accessorKey: 'details',
        header: 'Details',
        size: 500
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              if (row.original.filename) {
                getResourceDownloadUrl(row.original.filename).then(url => {
                  checkImage(row.original.filename);
                  setMediaPreview(url);
                });
              }

              setTitle(row.original.title);
              setDetails(row.original.details);
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CsButton
        label="New"
        icon={faPlus}
        onClick={() => {
          setTitle('');
          setDetails('');
          table.setCreatingRow(createRow(table));
        }}
      />
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => {
      return (
        <>
          <DialogTitle variant="h6">Add Video</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <input
              ref={mediaRef}
              type="file"
              accept="video/*"
              onChange={handleMediaPreview}
              hidden
            />

            {mediaPreview && (
              <div className="file-preview">
                {isImage ? (
                  <img
                    src={mediaPreview}
                    alt=""
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  <video
                    src={mediaPreview}
                    controls
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  >
                    <source src={mediaPreview} type="video/webm" />
                    <source src={mediaPreview} type="video/mp4" />
                  </video>
                )}
              </div>
            )}

            <CsButton
              label="Upload File"
              onClick={() => {
                mediaRef.current.click();
              }}
            />

            <TextField
              label="Title"
              variant="standard"
              value={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
            />

            <TextField
              label="Put your video details here..."
              variant="outlined"
              value={details}
              onChange={event => {
                setDetails(event.target.value);
              }}
              minRows={7}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </>
      );
    },
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
      return (
        <>
          <DialogTitle variant="h6">Edit Video</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >
            <input
              ref={mediaRef}
              type="file"
              accept="video/*"
              onChange={handleMediaPreview}
              hidden
            />

            {mediaPreview && (
              <div className="file-preview">
                {isImage ? (
                  <img
                    src={mediaPreview}
                    alt=""
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  <video
                    src={mediaPreview}
                    controls
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  >
                    <source src={mediaPreview} type="video/webm" />
                    <source src={mediaPreview} type="video/mp4" />
                  </video>
                )}
              </div>
            )}

            <CsButton
              label="Edit File"
              onClick={() => {
                mediaRef.current.click();
              }}
            />

            <TextField
              label="Title"
              variant="standard"
              value={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
            />

            <TextField
              label="Put your resource details here..."
              variant="outlined"
              value={details}
              onChange={event => {
                setDetails(event.target.value);
              }}
              minRows={7}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </>
      );
    },
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate,
    onCreatingRowCancel: () => {
      setMediaPreview('');
    },
    onEditingRowCancel: () => {
      setMediaPreview('');
    }
  });

  return (
    <div className="resources">
      <div className="resources__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

const MemberResources = () => {
  const [data, setData] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');

  const mediaRef = useRef(null);
  const [mediaPreview, setMediaPreview] = useState('');

  const checkImage = file => {
    const imageExtensions = ['.gif', '.jpg', '.jpeg', '.png'];
    let flag = false;
    imageExtensions.forEach(e => {
      if (file.includes(e)) {
        flag = true;
      }
    });
    setIsImage(flag);
  };

  const handleMediaPreview = event => {
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    checkImage(event.target.files[0].name);
    setMediaPreview(objectUrl);
  };

  const getData = () => {
    getResourcesListData().then(res => {
      setData(res.resources);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        size: 200
      },
      {
        accessorKey: 'details',
        header: 'Details',
        size: 500
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    enableEditing: true,
    createDisplayMode: 'modal',
    state: {
      isLoading
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Watch">
          <IconButton
            onClick={() => {
              if (row.original.filename) {
                getResourceDownloadUrl(row.original.filename).then(url => {
                  checkImage(row.original.filename);
                  setMediaPreview(url);
                });
              }

              setTitle(row.original.title);
              setDetails(row.original.details);
              table.setEditingRow(row);
            }}
          >
            <Tv />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
      return (
        <>
          <DialogTitle variant="h6">Watching Video</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >
            <input
              ref={mediaRef}
              type="file"
              accept="video/*"
              onChange={handleMediaPreview}
              hidden
            />

            {mediaPreview && (
              <div className="file-preview">
                {isImage ? (
                  <img
                    src={mediaPreview}
                    alt=""
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  <video
                    src={mediaPreview}
                    autoPlay
                    controls
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  >
                    <source src={mediaPreview} type="video/webm" />
                    <source src={mediaPreview} type="video/mp4" />
                  </video>
                )}
              </div>
            )}

            <DialogActions>
              <CsButton
                label="Close"
                onClick={() => {
                  table.setEditingRow(false);
                }}
              />
            </DialogActions>
          </DialogContent>
        </>
      );
    },
    onEditingRowCancel: () => {
      setMediaPreview('');
    }
  });

  return (
    <div className="resources">
      <div className="resources__list">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

const Resources = () => {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');

  return (
    <div className="resources">
      {isAdmin ? <AdminResources /> : <MemberResources />}
    </div>
  );
};

export default Resources;
