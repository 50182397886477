import './../styles/comps/cs-select.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

function CsSelect({
  keyRef = 'value',
  displayRef = 'label',
  options = [],
  label = 'Please select option',
  onSelect = () => {}
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleOnSelect = option => {
    setSelectedOption(option);
    onSelect(option);

    setShowOptions(false);
  };

  return (
    <div className="cs-select">
      <div className="label">
        <button onClick={handleShowOptions}>
          {selectedOption[displayRef] || label}
        </button>
        <FontAwesomeIcon
          icon={showOptions ? faCaretUp : faCaretDown}
          onClick={handleShowOptions}
        />
      </div>

      <ul className={'options' + (showOptions ? ' show' : '')}>
        {options.map(option => {
          return (
            <li
              key={option[keyRef]}
              className={
                selectedOption[keyRef] === option[keyRef] ? 'selected' : ''
              }
              onClick={() => {
                handleOnSelect(option);
              }}
            >
              {option[displayRef]}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default CsSelect;
