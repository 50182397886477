import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc
} from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { getUserFromSessionStorage } from './../services/auth';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from 'firebase/storage';

export const getPostsListData = async () => {
  try {
    const posts = await getDocs(
      query(collection(db, 'forum'), orderBy('posted_at', 'desc'))
    );

    let postsData = [];

    posts.forEach(doc => {
      postsData = [...postsData, { ...doc.data(), id: doc.id }];
    });

    return { posts: postsData };
  } catch (error) {
    return error;
  }
};

export const getPostDownloadUrl = async filename => {
  try {
    const storageRef = ref(storage, 'posts/' + filename);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

export const createPost = async data => {
  try {
    const user = getUserFromSessionStorage();
    let filename = null;

    if (data.file) {
      filename =
        user.id +
        '_' +
        new Date().getTime() +
        '.' +
        data.file.name.split('.').pop();
      await uploadBytes(ref(storage, 'posts/' + filename), data.file);
    }

    const res = await addDoc(collection(db, 'forum'), {
      filename,
      posted_at: new Date().getTime(),
      posted_by: user.staff_id,
      poster_name: [user.first_name, user.last_name].join(' '),
      title: data.title,
      details: data.details,
      status: 'Active',
      created_at: new Date().getTime(),
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const updatePost = async data => {
  try {
    const user = getUserFromSessionStorage();
    let filename = null;

    if (data.file) {
      filename =
        user.id +
        '_' +
        new Date().getTime() +
        '.' +
        data.file.name.split('.').pop();
      await uploadBytes(ref(storage, 'posts/' + filename), data.file);
      await deleteObject(ref(storage, 'posts/' + data.filename));
    }

    const details = {
      title: data.title,
      details: data.details,
      updated_at: new Date().getTime()
    };

    const res = await updateDoc(
      doc(db, 'forum', data.id),
      data.file
        ? {
            filename,
            ...details
          }
        : details
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const deletePost = async data => {
  try {
    if (data.filename) {
      await deleteObject(ref(storage, 'posts/' + data.filename));
    }

    const res = await deleteDoc(doc(db, 'forum', data.id));

    return res;
  } catch (error) {
    return error;
  }
};
