import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

/** Pre-membership code */
export const validateCode = async code => {
  try {
    const codes = await getDocs(
      query(
        collection(db, 'pre_membership_codes'),
        where('code', '==', code),
        where('status', '==', 'Valid')
      )
    );

    return { success: !codes.empty };
  } catch (error) {
    return error;
  }
};
