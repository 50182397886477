import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc
} from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { getUserFromSessionStorage } from './../services/auth';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from 'firebase/storage';

export const getResourcesListData = async () => {
  try {
    const resources = await getDocs(
      query(collection(db, 'resources'), orderBy('created_at', 'desc'))
    );

    let resourcesData = [];

    resources.forEach(doc => {
      resourcesData = [...resourcesData, { ...doc.data(), id: doc.id }];
    });

    return { resources: resourcesData };
  } catch (error) {
    return error;
  }
};

export const getResourceDownloadUrl = async filename => {
  try {
    const storageRef = ref(storage, 'resources/' + filename);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

export const createResource = async data => {
  try {
    const user = getUserFromSessionStorage();
    let filename = null;

    if (data.file) {
      filename =
        user.id +
        '_' +
        new Date().getTime() +
        '.' +
        data.file.name.split('.').pop();
      await uploadBytes(ref(storage, 'resources/' + filename), data.file);
    }

    const res = await addDoc(collection(db, 'resources'), {
      filename,
      created_by: user.staff_id,
      creator_name: [user.first_name, user.last_name].join(' '),
      title: data.title,
      details: data.details,
      status: 'Active',
      created_at: new Date().getTime(),
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const updateResource = async data => {
  try {
    const user = getUserFromSessionStorage();
    let filename = null;

    if (data.file) {
      filename =
        user.id +
        '_' +
        new Date().getTime() +
        '.' +
        data.file.name.split('.').pop();
      await uploadBytes(ref(storage, 'resources/' + filename), data.file);
      await deleteObject(ref(storage, 'resources/' + data.filename));
    }

    const details = {
      title: data.title,
      details: data.details,
      updated_at: new Date().getTime()
    };

    const res = await updateDoc(
      doc(db, 'resources', data.id),
      data.file
        ? {
            filename,
            ...details
          }
        : details
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteResource = async data => {
  try {
    if (data.filename) {
      await deleteObject(ref(storage, 'resources/' + data.filename));
    }

    const res = await deleteDoc(doc(db, 'resources', data.id));

    return res;
  } catch (error) {
    return error;
  }
};
