import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../styles/comps/cs-input.scss';
import React from 'react';

function CsInput({
  type = 'text',
  value = '',
  icon = null,
  mode = 'normal',
  placeholder = '',
  min,
  minLength,
  max,
  maxLength,
  className = '',
  classContainer = '',
  readOnly = false,
  disabled = false,
  onChange = () => {},
  onIconClick = () => {}
}) {
  const handleOnChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <div className={'cs-input ' + classContainer}>
      <input
        className={mode}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
      />

      {icon ? (
        <FontAwesomeIcon
          icon={icon}
          style={{ paddingRight: 10 }}
          onClick={onIconClick}
        />
      ) : null}
    </div>
  );
}

export default CsInput;
