import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc
} from 'firebase/firestore';
import { db } from '../../firebase';

export const getCalculatorSettings = async () => {
  try {
    const calculatorSettings = await getDocs(
      collection(db, 'calculator_settings')
    );

    let data = [];

    calculatorSettings.forEach(doc => {
      data = [...data, { ...doc.data(), id: doc.id }];
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const createCalculatorSetting = async data => {
  try {
    const res = await addDoc(collection(db, 'calculator_settings'), {
      field: data.field,
      desc: data.desc,
      max_amount: data.max_amount,
      max_amount_text: data.max_amount_text,
      max_term: data.max_term,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: 'Active'
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateCalculatorSetting = async data => {
  try {
    const res = await updateDoc(doc(db, 'calculator_settings', data.id), {
      field: data.field,
      desc: data.desc,
      max_amount: data.max_amount,
      max_amount_text: data.max_amount_text,
      max_term: data.max_term,
      loan_rate: data.loan_rate,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    debugger;
    return error;
  }
};

export const deleteCalculatorSetting = async id => {
  try {
    const res = await deleteDoc(doc(db, 'calculator_settings', id));

    return res;
  } catch (error) {
    return error;
  }
};

export const updateLoanRateSetting = async data => {
  try {
    const res = await updateDoc(doc(db, 'calculator_settings', data.id), {
      loan_rate: data.loan_rate,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};
