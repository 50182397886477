import './../styles/comps/cs-button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function CsButton({
  label = 'button',
  type = 'button',
  icon = null,
  mode = 'normal',
  className = '',
  classContainer = '',
  disabled = false,
  onClick = () => {}
}) {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <div className={'cs-button ' + classContainer}>
      <button
        className={mode + ' ' + className}
        type={type}
        onClick={handleOnClick}
        disabled={disabled}
      >
        {label}
        {icon ? <FontAwesomeIcon icon={icon} /> : null}
      </button>
    </div>
  );
}

export default CsButton;
