import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from 'firebase/storage';
import { db, storage } from '../../firebase';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { dateFormat } from './../utils/dateFormat';

export const getFormDownloadUrl = async filename => {
  try {
    const storageRef = ref(storage, 'forms/' + filename);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

export const uploadForm = async data => {
  try {
    const storageRef = ref(storage, 'forms/' + data.filename);

    return await uploadBytes(storageRef, data.file);
  } catch (error) {
    return error;
  }
};

export const deleteForm = async data => {
  try {
    const storageRef = ref(storage, 'forms/' + data.filename);

    return await deleteObject(storageRef);
  } catch (error) {
    return error;
  }
};

export const submitLoanApplication = async data => {
  try {
    const uniqueRef = new Date().getTime();
    const files = [];

    for (let i = 0; i < data.files.length; i++) {
      const file = data.files[i];
      const filename =
        data.user.client_id + // Client ID
        '-' +
        uniqueRef + // Unique reference
        '-' +
        i + // Order count
        '-' +
        data.details.field + // Loan type
        '.' +
        file.name.split('.').pop(); // File name extension

      files.push({ filename, file });
    }

    await Promise.allSettled(
      files.map(({ filename, file }) =>
        uploadBytes(ref(storage, 'loan-applications/' + filename), file)
      )
    );

    const res = await addDoc(collection(db, 'loan_applications'), {
      client_name: [data.user.first_name, data.user.last_name].join(' '),
      client_id: data.user.client_id,
      loan_type: data.details.field,
      filenames: files.map(x => x.filename),
      created_at: new Date().getTime(),
      updated_at: null,
      status: 'Pending'
    });

    // window.open(
    //   `mailto:loans@dsebspcc.com?subject=Loan Application: ${data.details.field}`
    // );

    if (res.id) {
      const attachments = await Promise.all(
        files.map(async ({ filename }) => ({
          filename,
          path: await getLoanDownloadUrl(filename)
        }))
      );

      await addDoc(collection(db, 'auto_send_email'), {
        type: 'loan_application',
        to: ['loans@dsebspcc.com'],
        message: {
          subject: `Website Loan Application from ${
            data.user.client_id
          } on ${dateFormat(new Date())}`,
          html: `<p style="color: black">
          A new loan application has been sent by ${data.user.client_id} ${[
            data.user.first_name,
            data.user.last_name
          ].join(' ')}, email: ${data.user.email}, contact: ${
            data.user.contact || 'N/A'
          }.
          Please see attached file for more information.
          </p>`,
          attachments
        }
      });
    }

    return res;
  } catch (error) {
    debugger;
    return error;
  }
};

export const getLoanDownloadUrl = async filename => {
  try {
    const storageRef = ref(storage, 'loan-applications/' + filename);

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

export const approveLoanApplication = async id => {
  try {
    const res = await updateDoc(doc(db, 'loan_applications', id), {
      status: 'Approved',
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const declineLoanApplication = async id => {
  try {
    const res = await updateDoc(doc(db, 'loan_applications', id), {
      status: 'Declined',
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};
