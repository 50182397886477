import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../firebase';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc
} from 'firebase/firestore';
import { orderBy } from 'lodash';

/** Pre-membership code */
export const getCodeListData = async () => {
  try {
    const codes = await getDocs(
      query(collection(db, 'pre_membership_codes'), orderBy('order', 'asc'))
    );

    let codesData = [];

    codes.forEach(doc => {
      codesData = [...codesData, { ...doc.data(), id: doc.id }];
    });

    return { codes: codesData };
  } catch (error) {
    return error;
  }
};

export const createCode = async data => {
  try {
    const res = await addDoc(collection(db, 'pre_membership_codes'), {
      code: data.code,
      status: data.status,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateCode = async data => {
  try {
    const res = await updateDoc(doc(db, 'pre_membership_codes', data.id), {
      code: data.code,
      status: data.status,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteCode = async id => {
  try {
    const res = await deleteDoc(doc(db, 'pre_membership_codes', id));

    return res;
  } catch (error) {
    return error;
  }
};

/** Video Seminar */
export const uploadVideoSeminar = async data => {
  try {
    // const user = getUserFromSessionStorage();
    // const filename =
    //   user.id +
    //   '_' +
    //   new Date().getTime() +
    //   '.' +
    //   data.file.name.split('.').pop();

    const res = await uploadBytes(
      ref(storage, 'pre-membership/video-seminar'),
      data.file
    );

    return res;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getVideoSeminar = async () => {
  try {
    const storageRef = ref(storage, 'pre-membership/video-seminar');

    return await getDownloadURL(storageRef);
  } catch (error) {
    return error;
  }
};

/** Quiz */
export const getQuestionListData = async () => {
  try {
    const questions = await getDocs(
      query(collection(db, 'pre_membership_quiz'), orderBy('order', 'asc'))
    );

    let questionsData = [];

    questions.forEach(doc => {
      questionsData = [...questionsData, { ...doc.data(), id: doc.id }];
    });

    return { questions: questionsData };
  } catch (error) {
    return error;
  }
};

export const createQuestion = async data => {
  try {
    const res = await addDoc(collection(db, 'pre_membership_quiz'), {
      order: data.order,
      question_type: data.question_type,
      question: data.question,
      answer: data.answer,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: 'Active'
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateQuestion = async data => {
  try {
    const res = await updateDoc(doc(db, 'pre_membership_quiz', data.id), {
      order: data.order,
      question_type: data.question_type,
      question: data.question,
      answer: data.answer,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteQuestion = async id => {
  try {
    const res = await deleteDoc(doc(db, 'pre_membership_quiz', id));

    return res;
  } catch (error) {
    return error;
  }
};

export const updateQuestionOptions = async data => {
  try {
    const res = await updateDoc(doc(db, 'pre_membership_quiz', data.id), {
      options: data.options,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

/** Guide */
export const getGuideListData = async () => {
  try {
    const guides = await getDocs(
      query(collection(db, 'pre_membership_guide'), orderBy('order', 'asc'))
    );

    let guidesData = [];

    guides.forEach(doc => {
      guidesData = [...guidesData, { ...doc.data(), id: doc.id }];
    });

    return { guides: guidesData };
  } catch (error) {
    return error;
  }
};

export const createGuide = async data => {
  try {
    const res = await addDoc(collection(db, 'pre_membership_guide'), {
      order: data.order,
      instruction: data.instruction,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: 'Active'
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateGuide = async data => {
  try {
    const res = await updateDoc(doc(db, 'pre_membership_guide', data.id), {
      order: data.order,
      instruction: data.instruction,
      updated_at: new Date().getTime()
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteGuide = async id => {
  try {
    const res = await deleteDoc(doc(db, 'pre_membership_guide', id));

    return res;
  } catch (error) {
    return error;
  }
};
