import { Link } from 'react-router-dom';
import CsButton from '../../comps/CsButton';
import CsInput from '../../comps/CsInput';
import '../../styles/login.scss';
import React, { useEffect, useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  createAuthentication,
  emailTemporaryPassword,
  getUser,
  logUser,
  login,
  logout
} from '../../core/services/auth';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import { useCSVReader } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import { importUsers } from '../../core/services/soa';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [emailSend, setEmailSend] = useState();

  const handleLogin = async () => {
    const userData = await getUser({ email });

    if (userData === null) {
      alert(
        'This account has been does not exist. Please contact your administrator.'
      );

      return;
    }

    if (userData.status === 'Deactivated') {
      logout();
      alert(
        'This account has been deactivated. Please contact your administrator.'
      );

      return;
    }

    /** TEMPORARY: creates authentication for first time login */
    if (!userData.authenticated && userData.password === password) {
      await createAuthentication({ id: userData.id, email, password });
    }

    const res = await login({ email, password });

    if (res.code === 'auth/invalid-credential') {
      alert(res.message);
    } else {
      logUser({ log_type: 'login', user: userData });
    }
  };

  const handleBeAMember = () => {
    navigate('/be-a-member');
  };

  const handleEmailTempPass = async () => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('a');
    // alert(email);
    return;
    // const res = await emailTemporaryPassword(email);

    // if (res.success) {
    //   alert('Your temporary password has been sent. Please check your emaiil.');
    // } else {
    //   alert(
    //     'Sending failed. Please make sure you have entered the correct email.'
    //   );
    // }
  };

  const { CSVReader } = useCSVReader();

  useEffect(() => {
    if (window !== undefined) {
      handleEmailTempPass(email);
    }
  }, []);

  return (
    <div className="login">
      <div className="login__grid">
        <div className="login__form">
          <div className="logo">
            <img src="/logo-icon.png" alt="logo icon" />
          </div>

          <div className="welcome-tag">
            <span className="welcome">Welcome</span>
            <span className="member-portal">Member Portal</span>
          </div>

          <div className="description">
            <p>Welcome DSEBSPCC Members! </p>
            <p>
              Track your savings, Manage benefits, Contact support, View
              announcements, Avail Loans, and more. All in one portal.
            </p>
          </div>

          <div className="form">
            <div className="input-group">
              <label htmlFor="">Email</label>
              <CsInput
                classContainer="login-input"
                value={email}
                onChange={setEmail}
              />
            </div>
            <div className="input-group">
              <label htmlFor="">Password</label>
              <CsInput
                classContainer="login-input"
                type={showPassword ? 'text' : 'password'}
                icon={showPassword ? faEyeSlash : faEye}
                value={password}
                onChange={setPassword}
                onIconClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>

            <CsButton
              label="Login"
              classContainer="login-btn"
              onClick={handleLogin}
            />

            <CsButton
              label="Become a Member"
              classContainer="be-a-member-btn"
              onClick={handleBeAMember}
            />

            {/* <CSVReader
              onUploadAccepted={results => {
                const { data } = results;
                const objectify = data.map(rowData => {
                  let result = {};
                  let colCount = data[0].length;
                  let colNames = data[0];

                  for (let i = 0; i < colCount; i++) {
                    result[colNames[i]] = rowData[i];
                  }

                  return result;
                });

                objectify.shift();

                importUsers(objectify);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
                Remove
              }) => (
                <div className="csv-reader" {...getRootProps()}>
                  {acceptedFile ? (
                    <div className="file">
                      <div className="info">
                        <span className="size">
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span className="name">{acceptedFile.name}</span>
                      </div>
                      <div className="progress-bar">
                        <ProgressBar />
                      </div>
                      <div className="remove" {...getRemoveFileProps()}>
                        <Remove />
                      </div>
                    </div>
                  ) : (
                    'Upload CSV File'
                  )}
                </div>
              )}
            </CSVReader> */}
          </div>

          <div className="outro">
            {/* <p>
              Not yet a member?{' '}
              <Link className="sign-up-link">Become a member here.</Link>
            </p> */}

            <Link className="forgot-password" to={'/forgot-password'}>
              Forgot your password?
            </Link>
          </div>
        </div>
        <div className="login__image">
          <img
            src="/bsp-building.png"
            alt="bsp building"
            style={{ visibility: 'hidden' }}
          />

          <div className="banner">
            <img src="/logo-icon.png" alt="logo icon" />
            {/* <p>
              DSE (BSP) CREDIT COOPERATIVE BANKO SENTRAL NG PILIPINAS MANIILA,
              PHILIPPINES
            </p> */}
          </div>
        </div>
      </div>

      <Dialog open={false}>
        <DialogTitle>Security Protocol</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Good day! Our beta phase has come to an end and as part of our
            security protocol, you will be redirected to our new web address:
            https://portal.dsebspcc.com.
            <br />
            <br />
            You will be given a temporary password via email and use it to to
            login to our new address.
            <br />
            <br />
            Please change your password upon logging in. Thank you for your
            cooperation. Have a nice day!
            <br />
            <br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            fullWidth
            value={emailSend}
            name="email"
            type="email"
            label="Please enter your email"
            variant="outlined"
            onChange={event => {
              const { value } = event.currentTarget;
              setEmailSend(value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!emailSend}
            variant="contained"
            type="submit"
            onClick={() => {
              // window.location = `http://localhost:3000/login?a=${emailSend}`;
              window.open('mailto:jcedb07@gmail.com');
            }}
          >
            Send Temporary Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
