import './../../styles/pages/member-savings.scss';
import React, { useEffect, useState } from 'react';
import CsButton from './../../comps/CsButton';
import { moneyFormat } from '../../core/utils/moneyFormat';
import { getMemberBalanceData } from '../../core/services/balance';
import { useNavigate } from 'react-router-dom';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { dateFormat } from '../../core/utils/dateFormat';

const MemberSavings = () => {
  const navigate = useNavigate();
  const user = getUserFromSessionStorage();

  const [recentSOA, setRecentSOA] = useState();
  const [savingsAccounts, setSavingsAccounts] = useState(0);
  const [savingsDepositAmount, setSavingsDepositAmount] = useState(0);
  const [timeDepositAmount, setTimeDepositAmount] = useState(0);
  const [shareCapitalAmount, setShareCapitalAmount] = useState(0);

  useEffect(() => {
    if (window !== undefined) {
      getMemberBalanceData({ user, date: recentSOA }).then(res => {
        setSavingsAccounts(
          res.balances.filter(x => x.type === 'Savings Deposit').length
        );

        setSavingsDepositAmount(
          res.balances.filter(x => x.type === 'Savings Deposit')[0]?.amount || 0
        );

        setTimeDepositAmount(
          res.balances.filter(x => x.type === 'Time Deposit')[0]?.amount || 0
        );

        setShareCapitalAmount(
          res.balances.filter(x => x.type === 'Share Capital')[0]?.amount || 0
        );
      });
    }
  }, [recentSOA]);

  const handleDownloadSOA = () => {
    navigate('/download-soa', {
      state: {
        date: recentSOA
      }
    });
  };

  return (
    <div className="member-savings">
      <div className="member-savings__section section-1">
        <h1 className="member-savings__title">
          Statement of Account for Savings
        </h1>

        <div className="member-savings__info">
          <div
            className="member-savings__col"
            style={{
              // borderRight: '1px solid #B3BCCE'
            }}
          >
            <h2>{savingsAccounts}</h2>
            <p>Savings Accounts</p>
          </div>

          <div className="member-savings__col">
            <select
              id="recent-soa-date"
              defaultValue={user.recent_soa_log.assigned_at}
              onChange={event => {
                setRecentSOA(event.target.value);
              }}
            >
              {(user?.soa_dates || []).sort((a, b) => a.localeCompare(b)).reverse().map(date => (
                <option key={date} value={date}>
                  {dateFormat(date)}
                </option>
              ))}
            </select>
            <p>Recent SOA</p>
          </div>
        </div>
      </div>

      <div className="member-savings__grid">
        <div className="member-savings__grid--item">
          <h2 className="title">Savings SOA Summary</h2>
          <div className="balances">
            <div className="highlighted">
              <p>Savings Balance:</p>
              <p>{moneyFormat(savingsDepositAmount)}</p>
            </div>
            <div>
              <p>Share Capital:</p>
              <p>{moneyFormat(shareCapitalAmount)}</p>
            </div>
            <div className="highlighted">
              <p>Time Deposit Balance:</p>
              <p>{moneyFormat(timeDepositAmount)}</p>
            </div>
          </div>
        </div>

        <div className="member-savings__grid--item no-card">
          <div className="download">
            <p>
              Should you wish to request for a Full SOA of your member account,
              click the button below
            </p>
            <CsButton
              label="Download Full SOA"
              className="button"
              onClick={handleDownloadSOA}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberSavings;
