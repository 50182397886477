import './../../styles/pages/members-logs.scss';
import React from 'react';
import CsButton from './../../comps/CsButton';
import CsInput from './../../comps/CsInput';
import { useState } from 'react';
import { generateOnlineLogs } from '../../core/services/members';
import csvDownload from 'json-to-csv-export';
import { DateTime } from 'luxon';

const MembersLogs = () => {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const handleGenerateLogs = () => {
    generateOnlineLogs({ start, end }).then(res => {
      if (res.length) {
        const data = res.map(x => ({
          client_id: x.client_id,
          staff_id: x.staff_id,
          log_type: x.log_type,
          user_name: x.user_name,
          executed_at: DateTime.fromJSDate(new Date(x.executed_at)).toFormat(
            'yyyy-MM-dd HH:mm'
          )
        }));

        csvDownload({
          data,
          filename: 'dsebspcc_online_logs',
          delimiter: ',',
          headers: [
            'Client ID',
            'Staff ID',
            'Log Type',
            'Name',
            'Executed Datetime'
          ]
        });
      } else {
        alert('No data available. Please try another date range.');
      }
    });
  };

  return (
    <div className="members-logs">
      <div className="members-logs__list">
        <div style={{ width: 200 }}>
          <div style={{ marginBottom: 20 }}>
            <label htmlFor="">From</label>
            <CsInput
              type="date"
              value={start}
              onChange={value => {
                setStart(value);
              }}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <label htmlFor="">To</label>
            <CsInput
              type="date"
              value={end}
              onChange={value => {
                setEnd(value);
              }}
            />
          </div>
          <CsButton
            label="Generate Logs"
            onClick={() => {
              handleGenerateLogs();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MembersLogs;
