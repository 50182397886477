import React, { useState } from 'react';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const AdminNavbar = () => {
  const navigate = useNavigate();

  return (
    <div className="navbar__online">
      {/* <div className="navbar__online-counter">
        Online Members <span>1</span>
      </div> */}
      {/* <div className="navbar__online-indicator">
        <span></span> Online
      </div> */}

      <div
        className="navbar__forum"
        onClick={() => {
          navigate('/announcements');
        }}
      >
        <FontAwesomeIcon icon={faBullhorn} color="#fff" size="xl" />
      </div>
    </div>
  );
};

const MemberNavbar = () => {
  const navigate = useNavigate();

  return (
    <div className="navbar__online">
      {/* <div className="navbar__online-counter">
        Online <span>1</span>
      </div> */}
      {/* <div className="navbar__online-indicator">Request Fund Withdrawal</div> */}

      <div
        className="navbar__forum"
        onClick={() => {
          navigate('/announcements');
        }}
      >
        <FontAwesomeIcon icon={faBullhorn} color="#fff" size="xl" />
      </div>
    </div>
  );
};

const Navbar = ({ showSidebar, setShowSidebar }) => {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');

  return (
    <div className="navbar">
      <div className='menu-button'>
        <IconButton
          onClick={() => {
            setShowSidebar(!showSidebar);
          }}
        >
          <Menu />
        </IconButton>
      </div>
      {isAdmin ? <AdminNavbar /> : <MemberNavbar />}</div>
  );
};

export default Navbar;
